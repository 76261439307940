import { GraphQLClient } from "graphql-request";
import {
  USER_ADMIN_TOGGLE_MUTATION,
  USER_HEADER_BANNER_DISMISS_MUTATION,
  UserAdminToggleMutationDTO,
  UserAdminToggleMutationParams,
  USER_COOKIE_CONSENT_UPDATE_MUTATION,
  UserCookieConsentUpdateMutationDTO,
  UserCookieConsentUpdateMutationParams,
  UserHeaderBannerDismissMutationDTO,
  UserHeaderBannerDismissMutationParams,
  USER_LOGOUT_MUTATION,
  UserLogoutMutationParams,
  UserLogoutMutationDTO,
  USER_PREFERED_LOCALIZATION_UPDATE_MUTATION,
  UserPreferredLocalizationUpdateMutationDTO,
  UserPreferredLocalizationUpdateMutationParams,
} from "./user.mutations";
import {
  CURRENT_USER_ALL_QUERY,
  CURRENT_USER_QUERY,
  CurrentUserAllQueryDTO,
  CurrentUserAllQueryParams,
  CurrentUserQueryDTO,
  CurrentUserQueryParams,
  USER_CART_QUERY,
  USER_HEADER_BANNER_QUERY,
  UserCartQueryDTO,
  UserCartQueryParams,
  UserHeaderBannerQueryDTO,
  UserHeaderBannerQueryParams,
  UserCookieConsentQueryDTO,
  UserCookieConsentQueryParams,
  USER_COOKIE_CONSENT_QUERY,
  USER_PREFERRED_LOCALIZATION_QUERY,
  UserPreferredLocalizationQueryDTO,
  UserPreferredLocalizationQueryParams,
} from "./user.queries";
import { GeographicalRegionCode, Locale } from "../shared/Base";

type UserServiceContext = {
  publicClient: GraphQLClient;
  privateClient: GraphQLClient;
};

export type UserService = ReturnType<typeof UserService>;

export const UserService = (context: UserServiceContext) => ({
  // use this when you need to query all of the user data in a single request
  queryCurrentUserAll: () =>
    context.publicClient.request<
      CurrentUserAllQueryDTO,
      CurrentUserAllQueryParams
    >(CURRENT_USER_ALL_QUERY),

  // use when you only need to query the user cart
  queryUserCart: () =>
    context.publicClient.request<UserCartQueryDTO, UserCartQueryParams>(
      USER_CART_QUERY,
    ),

  // use when you only need to query the current user
  queryCurrentUser: () =>
    context.publicClient.request<CurrentUserQueryDTO, CurrentUserQueryParams>(
      CURRENT_USER_QUERY,
    ),

  queryUserHeaderBanner: (locale: string) =>
    context.publicClient.request<
      UserHeaderBannerQueryDTO,
      UserHeaderBannerQueryParams
    >(USER_HEADER_BANNER_QUERY, { locale: locale }),

  queryUserPreferredLocalization: () =>
    context.publicClient.request<
      UserPreferredLocalizationQueryDTO,
      UserPreferredLocalizationQueryParams
    >(USER_PREFERRED_LOCALIZATION_QUERY),

  mutateUserAdminToggle: () =>
    context.privateClient.request<
      UserAdminToggleMutationDTO,
      UserAdminToggleMutationParams
    >(USER_ADMIN_TOGGLE_MUTATION),

  mutateUserHeaderBannerDismiss: (slug: string) =>
    context.publicClient.request<
      UserHeaderBannerDismissMutationDTO,
      UserHeaderBannerDismissMutationParams
    >(USER_HEADER_BANNER_DISMISS_MUTATION, { slug }),

  queryUserCookieConsent: () =>
    context.publicClient.request<
      UserCookieConsentQueryDTO,
      UserCookieConsentQueryParams
    >(USER_COOKIE_CONSENT_QUERY),

  mutateUserCookieConsentUpdate: () =>
    context.publicClient.request<
      UserCookieConsentUpdateMutationDTO,
      UserCookieConsentUpdateMutationParams
    >(USER_COOKIE_CONSENT_UPDATE_MUTATION),

  mutateUserPreferedLocalizationUpdate: (
    locale: Locale,
    geographicalRegionCode: GeographicalRegionCode,
  ) =>
    context.publicClient.request<
      UserPreferredLocalizationUpdateMutationDTO,
      UserPreferredLocalizationUpdateMutationParams
    >(USER_PREFERED_LOCALIZATION_UPDATE_MUTATION, {
      locale,
      geographicalRegionCode,
    }),

  mutateUserLogout: () =>
    context.privateClient.request<
      UserLogoutMutationDTO,
      UserLogoutMutationParams
    >(USER_LOGOUT_MUTATION),
});
