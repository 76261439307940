import React from "react";
import { useTranslations } from "../../../helpers/translations";
import { Button } from "@/components/ui/button";

export const FeaturedLinks = () => {
  const t = useTranslations("nav");

  return (
    <>
      <Button
        asChild
        variant="secondaryGray"
        size="sm"
        className="ml-xl no-underline hover:no-underline"
      >
        <a
          data-analytic-id="navbar-free-video-call-link"
          href={t("video_call.href")}
        >
          {t("video_call.text")}
        </a>
      </Button>

      <Button
        asChild
        variant="default"
        size="sm"
        className="ml-md no-underline hover:no-underline"
      >
        <a
          data-analytic-id="main-nav-new-design-button"
          href={t("design_now.href")}
        >
          {t("design_now.text")}
        </a>
      </Button>
    </>
  );
};
