import React, { useState } from "react";
import { LocalizationSelectItem } from "./LocalizationSelectItem";
import {
  CanadaFlagIcon,
  EuFlagIcon,
  GlobeIcon,
  UsFlagIcon,
} from "./LocationIcons";
import {
  GeographicalRegionCode,
  Locale,
} from "../../../../services/graphql/shared/Base";
import { useUserContext } from "../../../../providers/UserProvider";
import { useLocale, useTranslations } from "../../../../helpers/translations";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";

interface RegionSelectorProps {
  lastSelectedLocale: Locale | undefined;
  triggerClassName?: string;
  labelClassName?: string;
}

export const RegionSelector = ({
  lastSelectedLocale,
  triggerClassName,
  labelClassName,
}: RegionSelectorProps) => {
  const { userPreferredLocalization, updateUserPreferedLocalization } =
    useUserContext();

  const locale = useLocale();

  const t = useTranslations("nav.userMenu.localization");

  const [lastSelectedRegion, setLastSelectedRegion] = useState<
    GeographicalRegionCode | undefined
  >(undefined);

  if (!userPreferredLocalization.data) return null;

  const localeSuccessfullyChanged =
    updateUserPreferedLocalization.isSuccess &&
    lastSelectedLocale &&
    locale !== lastSelectedLocale;

  const selectValue = (() => {
    if (!lastSelectedRegion || updateUserPreferedLocalization.isError) {
      return userPreferredLocalization.data.userPreferredLocalization
        .geographicalRegionCode;
    }

    return lastSelectedRegion;
  })();

  return (
    <>
      <div className={cn("text-sm-medium mb-xs", labelClassName)}>
        {t("region.label")}
      </div>
      <Select
        onValueChange={(value: GeographicalRegionCode) => {
          setLastSelectedRegion(value);

          updateUserPreferedLocalization.mutate(
            {
              locale: locale as Locale,
              geographicalRegionCode: value,
            },
            {
              onSuccess: () => {
                // Perform actions on success
                window.location.reload();
              },
            },
          );
        }}
        disabled={
          updateUserPreferedLocalization.isPending || localeSuccessfullyChanged
        }
        value={selectValue}
      >
        <SelectTrigger
          className={cn(
            "text-md-medium p-lg h-6xl text-primary",
            triggerClassName,
          )}
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <LocalizationSelectItem value={"CA"}>
            <CanadaFlagIcon />
            <span>{t("region.canada")}</span>
          </LocalizationSelectItem>
          <LocalizationSelectItem value={"US"}>
            <UsFlagIcon />
            <span>{t("region.usa")}</span>
          </LocalizationSelectItem>
          <LocalizationSelectItem value={"EU"}>
            <EuFlagIcon />
            <span>{t("region.eu")}</span>
          </LocalizationSelectItem>
          <LocalizationSelectItem value={"INTL"}>
            <GlobeIcon className="grow shrink-0" />
            <span className="truncate">{t("region.international")}</span>
          </LocalizationSelectItem>
        </SelectContent>
      </Select>
    </>
  );
};
