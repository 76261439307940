import { cn } from "@/lib/utils";
import React from "react";

interface SubMenuFooterProps {
  compact?: boolean;
  singleLink?: React.ReactNode;
  links?: {
    href: string;
    text: string;
    icon: React.ReactNode;
  }[];
}

export const SubMenuFooter = ({
  links,
  singleLink,
  compact,
}: SubMenuFooterProps) => (
  <ul
    className={cn("list-none m-0 p-0 flex items-center mt-4xl", {
      "py-2xl px-3xl bg-gray-50": !compact,
    })}
  >
    {singleLink}
    {links?.map((link) => (
      <a
        key={link.href}
        href={link.href}
        className="no-underline hover:no-underline text-brand-600 hover:text-brand-600 focus:text-brand-600 flex items-center"
      >
        {link.icon}
        {link.text}
      </a>
    ))}
  </ul>
);
