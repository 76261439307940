import React, { Dispatch, SetStateAction, useEffect } from "react";
import { LocalizationSelectItem } from "./LocalizationSelectItem";
import { Locale } from "../../../../services/graphql/shared/Base";
import { useUserContext } from "../../../../providers/UserProvider";
import { useLocale, useTranslations } from "../../../../helpers/translations";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
interface LocaleSelectorProps {
  lastSelectedLocale: Locale | undefined;
  setLastSelectedLocale: Dispatch<SetStateAction<Locale | undefined>>;
  triggerClassName?: string;
  labelClassName?: string;
}

export const LocaleSelector = ({
  lastSelectedLocale,
  setLastSelectedLocale,
  triggerClassName,
  labelClassName,
}: LocaleSelectorProps) => {
  const { userPreferredLocalization, updateUserPreferedLocalization } =
    useUserContext();

  const locale = useLocale();

  const t = useTranslations("nav.userMenu.localization");

  const localeSuccessfullyChanged =
    updateUserPreferedLocalization.isSuccess &&
    lastSelectedLocale &&
    locale !== lastSelectedLocale;

  useEffect(() => {
    if (localeSuccessfullyChanged) {
      // rails legacy behaviour
      window.location.reload();
    }
  }, [localeSuccessfullyChanged]);

  if (!userPreferredLocalization.data) return null;

  const selectValue = (() => {
    if (!lastSelectedLocale || updateUserPreferedLocalization.isError) {
      return locale;
    }

    return lastSelectedLocale;
  })();

  return (
    <>
      <div className={cn("text-sm-medium mb-xs", labelClassName)}>
        {t("language.label")}
      </div>
      <Select
        onValueChange={(value: Locale) => {
          setLastSelectedLocale(value);

          updateUserPreferedLocalization.mutate({
            locale: value,
            geographicalRegionCode:
              userPreferredLocalization.data.userPreferredLocalization
                .geographicalRegionCode,
          });
        }}
        disabled={
          updateUserPreferedLocalization.isPending || localeSuccessfullyChanged
        }
        value={selectValue}
      >
        <SelectTrigger
          className={cn(
            "text-md-medium p-lg h-6xl text-primary",
            triggerClassName,
          )}
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <LocalizationSelectItem value="en">
            {t("language.english")}
          </LocalizationSelectItem>
          <LocalizationSelectItem value="fr">
            {t("language.french")}
          </LocalizationSelectItem>
          <LocalizationSelectItem value="de">
            {t("language.german")}
          </LocalizationSelectItem>
        </SelectContent>
      </Select>
    </>
  );
};
