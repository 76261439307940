import {
  IconCopyright,
} from "@tabler/icons-react";

import LinkedinLogo from "../svgs/LinkedinLogo"; 
import FacebookLogo from "../svgs/FacebookLogo";
import YoutubeLogo from "../svgs/YoutubeLogo";
import InstagramLogo from "../svgs/InstagramLogo";

import { useTranslations } from "../helpers/translations";
import React from "react";

export const SubFooter: React.FC = () => {
  const t = useTranslations("footer");

  const currentYear = new Date().getFullYear();

  const internalLinks = [
    { href: "/terms-of-use", text: t("terms") },
    { href: "/privacy-policy", text: t("privacy") },
    { href: "/security", text: t("security") },
    { href: "/product-policy", text: t("product_policy") },
  ];

  const socialLinks = [
    {
      href: "https://www.linkedin.com/company/vention/",
      icon: <LinkedinLogo/>,
    },
    {
      href: "https://www.facebook.com/vention.io",
      icon: <FacebookLogo />,
    },
    {
      href: "https://www.instagram.com/vention.io/",
      icon: <InstagramLogo />,
    },
    {
      href: "https://www.youtube.com/channel/UCwCbtvoW8A3KrASjN5X2KrQ",
      icon: < YoutubeLogo/>,
    },
  ];

  return (
    <div className="bg-gray-light-800">
      <div className="container text-xs-regular flex items-center justify-between pt-6xl pb-7xl flex-col-reverse space-y-reverse space-y-3xl sm:flex-row sm:space-y-0">
        <div className="flex items-center justify-center space-x-4xl flex-wrap sm:justify-start flex-grow">
          <a
            href="/"
            className="flex items-center py-md lg:py-0 space-x-md text-white no-underline hover:no-underline hover:text-white focus:text-white"
          >
            <IconCopyright size={14} />
            <div>Vention {currentYear}</div>
          </a>

          {internalLinks.map((link, index) => (
            <a
              className="text-white py-md lg:py-0 no-underline hover:no-underline hover:text-white focus:text-white"
              key={index}
              href={link.href}
            >
              {link.text}
            </a>
          ))}
        </div>
        <div className="flex space-x-5xl sm:justify-end lg:space-x-4xl">
          {socialLinks.map((link, index) => (
            <a
              className="fill-white no-underline hover:no-underline hover:fill-gray-400 transition-all duration-[4ms] ease-linear focus:text-white"
              target="_blank"
              rel="noreferrer noopener"
              key={index}
              href={link.href}
            >
              {link.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
