import { SelectItem } from "@/components/ui/select";
import React from "react";

export const LocalizationSelectItem = React.forwardRef<
  React.ElementRef<typeof SelectItem>,
  React.ComponentPropsWithoutRef<typeof SelectItem>
>(({ children, ...props }, ref) => {
  return (
    <SelectItem ref={ref} {...props}>
      <div className="flex items-center space-x-md">{children}</div>
    </SelectItem>
  );
});

LocalizationSelectItem.displayName = "LocalizationSelectItem";
