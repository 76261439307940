import { cn } from "@/lib/utils";
import React from "react";

export const SubMenuHeading = ({
  children,
  compact,
}: {
  readonly children?: React.ReactNode;
  readonly compact?: boolean;
}) => (
  <h2 className={cn("text-lg-semibold mt-0", compact ? "mb-lg" : "mb-3xl")}>
    {children}
  </h2>
);
