import { gql } from "graphql-request";
import {
  ErrorMetadata,
  GeographicalRegionCode,
  Locale,
  ResultStatus,
} from "../shared/Base";

export const USER_ADMIN_TOGGLE_MUTATION = gql`
  mutation UserAdminToggleMutation {
    userToggleAdmin(input: {}) {
      legacyUserPreferences
      errors {
        title
        message
      }
    }
  }
`;

export type UserAdminToggleMutationDTO = {
  userToggleAdmin: {
    legacyUserPreferences: string;
  } & ErrorMetadata;
};

export type UserAdminToggleMutationParams = Record<string, never>;

export const USER_HEADER_BANNER_DISMISS_MUTATION = gql`
  mutation UserHeaderBannerDismissMutation($slug: String!) {
    userHeaderBannerDismiss(input: { slug: $slug }) {
      result {
        status
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UserHeaderBannerDismissMutationDTO = {
  userHeaderBannerDismiss: ResultStatus & ErrorMetadata;
};

export type UserHeaderBannerDismissMutationParams = {
  slug: string;
};

export const USER_COOKIE_CONSENT_UPDATE_MUTATION = gql`
  mutation userCookieConsentUpdate {
    userCookieConsentUpdate(input: {}) {
      result {
        status
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UserCookieConsentUpdateMutationDTO = {
  userCookieConsentUpdate: ResultStatus & ErrorMetadata;
};

export type UserCookieConsentUpdateMutationParams = Record<string, never>;

export const USER_PREFERED_LOCALIZATION_UPDATE_MUTATION = gql`
  mutation userPreferredLocalizationUpdate(
    $locale: Locale!
    $geographicalRegionCode: GeographicalRegionCode!
  ) {
    userPreferredLocalizationUpdate(
      input: {
        locale: $locale
        geographicalRegionCode: $geographicalRegionCode
      }
    ) {
      errors {
        message
        title
      }
      result {
        status
      }
    }
  }
`;

export type UserPreferredLocalizationUpdateMutationDTO = {
  userPreferredLocalizationUpdate: ResultStatus & ErrorMetadata;
};

export type UserPreferredLocalizationUpdateMutationParams = {
  locale: Locale;
  geographicalRegionCode: GeographicalRegionCode;
};

export const USER_LOGOUT_MUTATION = gql`
  mutation UserLogoutMutation {
    userLogout(input: {}) {
      result {
        status
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UserLogoutMutationDTO = {
  userLogout: ResultStatus & ErrorMetadata;
};

export type UserLogoutMutationParams = Record<string, never>;
