import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { useGraphQLContext } from "./GraphQLProvider";
import {
  UserAdminToggleMutationDTO,
  UserCookieConsentUpdateMutationDTO,
  UserCookieConsentUpdateMutationParams,
  UserHeaderBannerDismissMutationDTO,
  UserHeaderBannerDismissMutationParams,
  UserLogoutMutationDTO,
  UserLogoutMutationParams,
  UserPreferredLocalizationUpdateMutationDTO,
  UserPreferredLocalizationUpdateMutationParams,
} from "../services/graphql/user/user.mutations";
import {
  CurrentUserAllQueryDTO,
  UserCookieConsentQueryDTO,
  UserHeaderBannerQueryDTO,
  UserPreferredLocalizationQueryDTO,
} from "../services/graphql/user/user.queries";
import { UserService } from "../services/graphql/user/UserService";

interface UserContext {
  currentUserAll: UseQueryResult<CurrentUserAllQueryDTO, Error>;
  userHeaderBanner: UseQueryResult<UserHeaderBannerQueryDTO, Error>;
  userCookieConsent: UseQueryResult<UserCookieConsentQueryDTO, Error>;
  userPreferredLocalization: UseQueryResult<
    UserPreferredLocalizationQueryDTO,
    Error
  >;
  toggleUserAdmin: UseMutationResult<
    UserAdminToggleMutationDTO,
    Error,
    void,
    unknown
  >;
  dismissUserHeaderBanner: UseMutationResult<
    UserHeaderBannerDismissMutationDTO,
    Error,
    UserHeaderBannerDismissMutationParams,
    unknown
  >;
  updateUserCookieConsent: UseMutationResult<
    UserCookieConsentUpdateMutationDTO,
    Error,
    UserCookieConsentUpdateMutationParams,
    unknown
  >;
  updateUserPreferedLocalization: UseMutationResult<
    UserPreferredLocalizationUpdateMutationDTO,
    Error,
    UserPreferredLocalizationUpdateMutationParams,
    unknown
  >;
  logoutUser: UseMutationResult<
    UserLogoutMutationDTO,
    Error,
    UserLogoutMutationParams,
    unknown
  >;
}

interface UserProviderProps {
  locale: string;
  children: ReactNode;
}

const UserContext = createContext<UserContext | undefined>(undefined);

export const UserProvider = ({ locale, children }: UserProviderProps) => {
  const queryClient = useQueryClient();
  const { publicClient, privateClient } = useGraphQLContext();
  const userService = UserService({ publicClient, privateClient });

  const currentUserAll = useQuery<CurrentUserAllQueryDTO>({
    queryKey: ["currentUserAll"],
    queryFn: userService.queryCurrentUserAll,
  });

  const userHeaderBanner = useQuery<UserHeaderBannerQueryDTO>({
    queryKey: ["userHeaderBanner", locale],
    queryFn: () => userService.queryUserHeaderBanner(locale),
  });

  const userCookieConsent = useQuery<UserCookieConsentQueryDTO>({
    queryKey: ["userCookieConsent"],
    queryFn: userService.queryUserCookieConsent,
  });

  const userPreferredLocalization = useQuery<UserPreferredLocalizationQueryDTO>(
    {
      queryKey: ["userPreferredLocalization"],
      queryFn: userService.queryUserPreferredLocalization,
    },
  );

  const toggleUserAdmin = useMutation({
    mutationFn: userService.mutateUserAdminToggle,
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["currentUserAll"],
      }),
  });

  const dismissUserHeaderBanner = useMutation<
    UserHeaderBannerDismissMutationDTO,
    Error,
    UserHeaderBannerDismissMutationParams
  >({
    mutationFn: (variables) =>
      userService.mutateUserHeaderBannerDismiss(variables.slug),
  });

  const updateUserCookieConsent = useMutation<
    UserCookieConsentUpdateMutationDTO,
    Error,
    UserCookieConsentUpdateMutationParams
  >({
    mutationFn: userService.mutateUserCookieConsentUpdate,
  });

  const updateUserPreferedLocalization = useMutation<
    UserPreferredLocalizationUpdateMutationDTO,
    Error,
    UserPreferredLocalizationUpdateMutationParams
  >({
    mutationFn: (variables) =>
      userService.mutateUserPreferedLocalizationUpdate(
        variables.locale,
        variables.geographicalRegionCode,
      ),
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["currentUserAll", "userPreferredLocalization"],
      }),
  });

  const logoutUser = useMutation<
    UserLogoutMutationDTO,
    Error,
    UserLogoutMutationParams
  >({
    mutationFn: userService.mutateUserLogout,
    onSuccess: () =>
      // rails legacy behaviour
      window.location.reload(),
  });

  const memoizedValue = useMemo(
    () => ({
      currentUserAll,
      toggleUserAdmin,
      userHeaderBanner,
      userCookieConsent,
      userPreferredLocalization,
      dismissUserHeaderBanner,
      updateUserCookieConsent,
      updateUserPreferedLocalization,
      logoutUser,
    }),
    [
      currentUserAll,
      toggleUserAdmin,
      userHeaderBanner,
      userCookieConsent,
      userPreferredLocalization,
      dismissUserHeaderBanner,
      updateUserCookieConsent,
      updateUserPreferedLocalization,
      logoutUser,
    ],
  );

  return (
    <UserContext.Provider value={memoizedValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
