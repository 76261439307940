import { buildSearchBox } from "@coveo/headless";
import { IconSearch } from "@tabler/icons-react";
import { cn } from "@/lib/utils";
import React from "react";
import SearchBox from "./SearchBox";
import { createSearchEngine } from "../../../helpers/coveo/createEngine";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

export type GlobalSearchStandaloneProps = {
  accessToken: string;
  organizationId: string;
  variant: "popover" | "inline";
  jwtRenewGlobalSearch: () => Promise<string | null>;
  popoverContentClassName?: string;
  beforeSearchSubmit?: (value: string) => void;
  placeholder?: string;
  inputClassName?: string;
};

export const GlobalSearchStandalone = ({
  accessToken,
  organizationId,
  variant,
  jwtRenewGlobalSearch,
  popoverContentClassName,
  beforeSearchSubmit,
  placeholder,
  inputClassName,
}: GlobalSearchStandaloneProps) => {
  const [open, setOpen] = React.useState(false);

  const onSearchSubmit = (value: string) => {
    setOpen(false);
    beforeSearchSubmit?.(value);
    window.location.href = `/global-search#q=${value}`;
  };

  const searchEngine = createSearchEngine(
    organizationId,
    accessToken,
    "Global Search",
    jwtRenewGlobalSearch,
  );
  const searchBoxController = buildSearchBox(searchEngine);

  return (
    <div>
      {variant === "inline" ? (
        <SearchBox
          controller={searchBoxController}
          onSearchSubmit={onSearchSubmit}
          placeholder={placeholder}
          inputClassName={inputClassName}
        />
      ) : (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <span className="flex items-end cursor-pointer text-gray-400 hover:text-white  focus:text-white p-md">
              <IconSearch />
            </span>
          </PopoverTrigger>
          <PopoverContent
            className={cn(
              "tw-popover p-0 outline-0 rounded-lg",
              popoverContentClassName,
            )}
            side="bottom"
            align="end"
          >
            <SearchBox
              controller={searchBoxController}
              onSearchSubmit={onSearchSubmit}
              placeholder={placeholder}
              inputClassName={inputClassName}
            />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

export default GlobalSearchStandalone;
