import { cn } from "@/lib/utils";
import React from "react";

export const DropdownMenuItemLabel = React.forwardRef<
  HTMLSpanElement,
  React.ComponentPropsWithoutRef<"span">
>(({ children, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={cn("flex items-center", props.className)}
  >
    {children}
  </span>
));

DropdownMenuItemLabel.displayName = "DropdownMenuItemLabel";
