import React from "react";

const InstagramLogo = () => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8 md:w-6 md:h-6"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.19291 0H14.7959C17.7176 0 20.0946 2.47601 20.0945 5.51928V14.4807C20.0945 17.524 17.7176 20 14.7959 20H6.19291C3.27139 20 0.894531 17.5241 0.894531 14.4807V5.51928C0.894531 2.47601 3.27139 0 6.19291 0ZM14.7967 18.2254C16.779 18.2254 18.3918 16.5455 18.3918 14.4806H18.3917V5.51919C18.3917 3.45439 16.7789 1.77441 14.7965 1.77441H6.19352C4.2113 1.77441 2.59863 3.45439 2.59863 5.51919V14.4806C2.59863 16.5455 4.2113 18.2255 6.19352 18.2254H14.7967Z"
        fill="inherit"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.9043 10.4349C5.9043 7.55797 8.15114 5.21744 10.9129 5.21744C13.6748 5.21744 15.9216 7.55797 15.9216 10.4349C15.9216 13.3118 13.6748 15.6522 10.9129 15.6522C8.15114 15.6522 5.9043 13.3118 5.9043 10.4349ZM7.62793 10.4348C7.62793 12.321 9.10123 13.8555 10.912 13.8555C12.7228 13.8555 14.1961 12.321 14.1961 10.4348C14.1961 8.54843 12.7229 7.01382 10.912 7.01382C9.10112 7.01382 7.62793 8.54843 7.62793 10.4348Z"
        fill="inherit"
      />
      <path
        d="M16.3391 2.60889C16.0101 2.60889 15.6869 2.74762 15.4546 2.9907C15.2211 3.2326 15.0869 3.56936 15.0869 3.91323C15.0869 4.25604 15.2212 4.59268 15.4546 4.83576C15.6868 5.07766 16.0101 5.21758 16.3391 5.21758C16.6692 5.21758 16.9914 5.07766 17.2247 4.83576C17.4581 4.59268 17.5913 4.25592 17.5913 3.91323C17.5913 3.56936 17.4581 3.2326 17.2247 2.9907C16.9925 2.74762 16.6692 2.60889 16.3391 2.60889Z"
        fill="inherit"
      />
    </svg>
  );
};

export default InstagramLogo;
