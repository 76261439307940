import React from "react";

const LinkedinLogo = () => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8 md:w-6 md:h-6"
    >
      <path
        d="M1.398 19.9998H3.6247C3.93189 19.9998 4.1809 19.6804 4.1809 19.2863V6.80058C4.1809 6.40646 3.93189 6.08707 3.6247 6.08707H1.398C1.09077 6.08707 0.841797 6.40646 0.841797 6.80058V19.2863C0.841797 19.6803 1.09077 19.9998 1.398 19.9998Z"
        fill="inherit"
      />
      <path
        d="M1.398 3.47834H3.6247C3.93189 3.47834 4.1809 3.19803 4.1809 2.85221V0.626285C4.1809 0.280464 3.93189 0.000152588 3.6247 0.000152588H1.398C1.09077 0.000152588 0.841797 0.280464 0.841797 0.626285V2.85221C0.841797 3.19803 1.09077 3.47834 1.398 3.47834Z"
        fill="inherit"
      />
      <path
        d="M19.9033 7.09551C19.5399 6.54851 19.0042 6.09867 18.2958 5.74608C17.5875 5.39373 16.8056 5.21744 15.9501 5.21744C14.2134 5.21744 12.7419 5.97551 11.5354 7.49193C11.2954 7.79366 11.1208 7.72769 11.1208 7.31967V6.33493C11.1208 5.9269 10.8317 5.59625 10.475 5.59625H8.16536C7.80863 5.59625 7.51953 5.9269 7.51953 6.33493V19.261C7.51953 19.669 7.80866 19.9997 8.16536 19.9997H10.7509C11.1076 19.9997 11.3967 19.669 11.3967 19.261V14.7657C11.3967 12.8929 11.4956 11.6092 11.6933 10.9149C11.891 10.2205 12.2568 9.66292 12.7903 9.24206C13.3239 8.82119 13.9263 8.61066 14.5979 8.61066C15.1222 8.61066 15.5707 8.75798 15.9433 9.05268C16.3158 9.34733 16.5849 9.76009 16.7505 10.2915C16.9162 10.8229 16.9989 11.9933 16.9989 13.8031V19.261C16.9989 19.669 17.288 19.9997 17.6447 19.9997H20.2302C20.5869 19.9997 20.876 19.669 20.876 19.261V11.9407C20.876 10.6467 20.8047 9.65235 20.6623 8.95798C20.5198 8.26362 20.2668 7.64279 19.9033 7.09551Z"
        fill="inherit"
      />
    </svg>
  );
};

export default LinkedinLogo;
