import { IconShoppingCart } from "@tabler/icons-react";
import React from "react";
import { useUserContext } from "../../../providers/UserProvider";
import { CHECKOUT_CART_URL } from "../../../helpers/legacyRoutes";
export const CartStatus = () => {
  const { currentUserAll } = useUserContext();

  const cartItemCount = currentUserAll?.data?.userCart.item.itemsCount ?? 0;

  return (
    <a
      href={CHECKOUT_CART_URL}
      className="relative flex text-gray-400 hover:text-white py-md px-xs"
    >
      <IconShoppingCart width={32} />
      {cartItemCount > 0 && (
        <span className="absolute w-[20px] h-[20px] top-[-4px] right-[-4px] flex items-center justify-center rounded-full text-xs-medium bg-red-500 text-white">
          {cartItemCount}
        </span>
      )}
    </a>
  );
};
