import { cn } from "@/lib/utils";
import React from "react";

interface SubMenuImageProps {
  src: string;
  alt: string;
  width: number;
  height: number;
  className?: string;
}

export const SubMenuImage = ({
  src,
  alt,
  width,
  height,
  className,
}: SubMenuImageProps) => (
  <img
    src={src}
    alt={alt}
    className={cn("mb-2xl", className)}
    width={width}
    height={height}
    loading="lazy"
  />
);
