export const USER_SESSION_LOGIN_URL = '/users/sign_in';
export const USER_SESSION_LOGOUT_URL = '/users/sign_out';

export const DASHBOARD_URL = '/dashboard';
export const DASHBOARD_DESIGNS_URL = '/dashboard/designs';
export const DASHBOARD_ORDERS_URL = '/dashboard/orders';
export const DASHBOARD_DEPLOY_URL = '/dashboard/machine_cloud/machine';
export const DASHBOARD_ANALYTICS_URL = '/dashboard/machine_analytics';
export const DASHBOARD_SETTINGS_URL = '/dashboard/settings/user';
export const ADMIN_DASHBOARD_URL = '/admin';
export const ADMIN_CHECKOUT_URL = '/admin/checkout';
export const CHECKOUT_CART_URL = '/checkout/cart';
