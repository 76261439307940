import React from "react";
import { GraphQLProvider } from "../providers/GraphQLProvider";
import { UserProvider } from "../providers/UserProvider";
import { CoveoProvider } from "../providers/CoveoProvider";
import { MainHeaderBanner } from "../components/MainHeaderBanner";
import { PublicConfigProvider } from "../providers/PublicConfigProvider";
import { ReactQueryProvider } from "../providers/ReactQueryProvider";

type MainHeaderBannerWrapperProps = {
  readonly config: {
    readonly RAILS_ROOT_URL: string;
    readonly COVEO_ORGANIZATION_ID: string;
    readonly GRAPHQL_PUBLIC_API_URL: string;
    readonly GRAPHQL_PRIVATE_API_URL: string;
  };
  readonly locale: string;
};

export default function MainHeaderBannerWrapper({
  config,
  locale,
}: MainHeaderBannerWrapperProps) {
  return (
    <ReactQueryProvider>
      <PublicConfigProvider config={config}>
        <GraphQLProvider>
          <UserProvider locale={locale}>
            <CoveoProvider>
              <MainHeaderBanner />
            </CoveoProvider>
          </UserProvider>
        </GraphQLProvider>
      </PublicConfigProvider>
    </ReactQueryProvider>
  );
}
