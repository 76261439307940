import { cn } from "@/lib/utils";
import { IconArrowRight } from "@tabler/icons-react";
import React from "react";

interface ViewMoreLinkProps {
  href: string;
  text: string;
  className?: string;
}

export const ViewMoreLink = ({ href, text, className }: ViewMoreLinkProps) => (
  <a
    href={href}
    className={cn(
      className,
      "flex items-center text-brand-600 hover:text-brand-600 focus:text-brand-600 text-sm-semibold leading-none no-underline hover:no-underline [&_svg]:hover:translate-x-1",
    )}
  >
    {text}
    <IconArrowRight className="inline-block w-6 h-6 ml-lg transition-transform duration-200" />
  </a>
);
