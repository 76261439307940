// best fit between legacy breakpoints and tailwind
export const breakpoints = {
  xs: 479,
  sm: 767,
  md: 992,
  lg: 1070, // legacy navMainLarge
  xl: 1200,
  '2xl': 1400,
  '3xl': 1600,
  '4xl': 2000,
} as const;
