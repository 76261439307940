"use client";

import { cn } from "@/lib/utils";
import GlobalSearchStandalone from "../global-search/GlobalSearchStandalone";
import { CartStatus } from "./CartStatus";
import { FeaturedLinks } from "./FeaturedLinks";
import { UserMenu } from "./UserMenu";
import React from "react";
import { usePublicConfigContext } from "../../../providers/PublicConfigProvider";
import { useCoveoContext } from "../../../providers/CoveoProvider";

export const UtilityLinks = ({
  className,
}: {
  readonly className?: string;
}) => {
  const { COVEO_ORGANIZATION_ID } = usePublicConfigContext();
  const { jwtCreateGlobalSearch, jwtRenewGlobalSearch } = useCoveoContext();

  const globalSearchAccessToken =
    jwtCreateGlobalSearch.data?.coveoJwtCreate.jwt;

  return (
    <div className={cn("flex items-center", className)}>
      {COVEO_ORGANIZATION_ID && globalSearchAccessToken && (
        <GlobalSearchStandalone
          accessToken={globalSearchAccessToken}
          organizationId={COVEO_ORGANIZATION_ID}
          variant="popover"
          jwtRenewGlobalSearch={jwtRenewGlobalSearch}
          popoverContentClassName="max-lg:hidden"
        />
      )}

      <CartStatus />

      <UserMenu />

      <FeaturedLinks />
    </div>
  );
};
