import {
  AnalyticsConfiguration,
  buildSearchEngine,
  SearchEngine,
  SearchEngineOptions,
} from "@coveo/headless";

export const createSearchEngine = (
  organizationId: string,
  accessToken: string,
  pipeline: string,
  renewJwt: () => Promise<string | null>,
  preprocessRequestBody?: (
    body: Record<string, unknown>,
  ) => Record<string, unknown>,
  analytics?: AnalyticsConfiguration,
): SearchEngine => {
  // Only Coveo for Commerce supports EP at the moment.
  // For every other kind of implementation, set analyticsMode to legacy for the time being.
  // https://docs.coveo.com/en/headless/latest/usage/headless-usage-analytics/
  // https://docs.coveo.com/en/atomic/latest/usage/atomic-usage-analytics/
  const defaultAnalytics: AnalyticsConfiguration = {
    analyticsMode: "legacy",
  };

  const options: SearchEngineOptions = {
    configuration: {
      analytics: analytics || defaultAnalytics,
      organizationId: organizationId,
      accessToken: accessToken,
      search: { pipeline: pipeline },

      renewAccessToken() {
        return renewJwt().then((response) => response ?? "");
      },
    },
  };

  if (preprocessRequestBody) {
    options.configuration.preprocessRequest = (
      request,
      _clientOrigin,
      metadata,
    ) => {
      if (metadata?.method === "search" && request.body) {
        const body = JSON.parse(request.body.toString());

        request.body = JSON.stringify(preprocessRequestBody(body));
      }

      return request;
    };
  }

  return buildSearchEngine(options);
};
