import React from "react";
import { ListItem } from "./ListItem";
import { SubMenuHeading } from "./SubHeading";
import { ViewMoreLink } from "./ViewMoreLink";

interface LinkListProps {
  heading: string;
  items: {
    title: string;
    subtitle: React.ReactNode;
    href: string;
    icon?: React.ReactNode;
  }[];
  viewMore?: {
    text: string;
    href: string;
  };
  className?: string;
}

export const LinkList = ({
  heading,
  items,
  viewMore,
  className,
}: LinkListProps) => {
  return (
    <section className={className}>
      <SubMenuHeading>{heading}</SubMenuHeading>
      <ul className="list-none m-0 p-0">
        {items.map((item) => (
          <ListItem
            key={item.href}
            title={item.title}
            href={item.href}
            icon={item.icon}
          >
            {item.subtitle}
          </ListItem>
        ))}
        {viewMore && (
          <li className="mt-4xl">
            <ViewMoreLink href={viewMore.href} text={viewMore.text} />
          </li>
        )}
      </ul>
    </section>
  );
};
