import { gql } from 'graphql-request';

export const COVEO_JWT_CREATE_MUTATION = gql`
  mutation ($searchHub: String!) {
    coveoJwtCreate(input: { searchHub: $searchHub }) {
      jwt
    }
  }
`;

export type CoveoJwtCreateMutationDTO = {
  coveoJwtCreate: {
    jwt: string | null;
  };
};

export type CoveoJwtCreateMutationParams = {
  searchHub: 'GlobalSearch';
};
