import React from "react";

const FacebookLogo = () => {
  return (
    <svg
      viewBox="0 0 11 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8 md:w-6 md:h-6"
    >
      <path
        d="M10.5171 0.00416134L8.11441 0C5.41504 0 3.67059 1.9319 3.67059 4.92203V7.19141H1.25476C1.046 7.19141 0.876953 7.37409 0.876953 7.59943V10.8875C0.876953 11.1128 1.04619 11.2953 1.25476 11.2953H3.67059V19.5922C3.67059 19.8175 3.83963 20 4.04839 20H7.20036C7.40912 20 7.57816 19.8173 7.57816 19.5922V11.2953H10.4028C10.6116 11.2953 10.7806 11.1128 10.7806 10.8875L10.7818 7.59943C10.7818 7.49124 10.7419 7.38762 10.6711 7.31105C10.6004 7.23448 10.504 7.19141 10.4038 7.19141H7.57816V5.26763C7.57816 4.34298 7.78229 3.87358 8.89817 3.87358L10.5167 3.87295C10.7253 3.87295 10.8944 3.69027 10.8944 3.46514V0.411972C10.8944 0.187052 10.7255 0.00457747 10.5171 0.00416134Z"
        fill="inherit"
      />
    </svg>
  );
};

export default FacebookLogo;
