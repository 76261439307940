import { IconChevronLeft } from "@tabler/icons-react";
import { UseQueryResult } from "@tanstack/react-query";
import React, { Dispatch, SetStateAction, useState } from "react";
import { DropdownMenuItemIcon } from "./DropdownMenuItemIcon";
import { DropdownMenuItemLabel } from "./DropdownMenuItemLabel";
import { DropdownMenuItemLink } from "./DropdownMenuItemLink";
import { DropdownMenuItemSelect } from "./DropdownMenuItemSelect";
import { LocaleSelector } from "./LocaleSelector";
import { RegionSelector } from "./RegionSelector";
import { CurrentUserAllQueryDTO } from "../../../../services/graphql/user/user.queries";
import { Locale } from "../../../../services/graphql/shared/Base";
import { useTranslations } from "../../../../helpers/translations";
import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";

interface LocalizationMenuContentProps {
  currentUserAll: UseQueryResult<CurrentUserAllQueryDTO, Error>;
  setOpenedMenu: Dispatch<SetStateAction<"main" | "location">>;
}

export const LocalizationMenuContent = (
  props: LocalizationMenuContentProps,
) => {
  const { setOpenedMenu } = props;

  const [lastSelectedLocale, setLastSelectedLocale] = useState<
    Locale | undefined
  >(undefined);

  const t = useTranslations("nav.userMenu.localization");

  return (
    <>
      <DropdownMenuItemLink
        href="#"
        onClick={(event) => {
          event.preventDefault();
          setOpenedMenu("main");
        }}
      >
        <DropdownMenuItemIcon icon={IconChevronLeft} size={16} />
        <DropdownMenuItemLabel>{t("toggle")}</DropdownMenuItemLabel>
      </DropdownMenuItemLink>
      <DropdownMenuSeparator />
      <DropdownMenuItemSelect>
        <RegionSelector lastSelectedLocale={lastSelectedLocale} />
      </DropdownMenuItemSelect>
      <DropdownMenuItemSelect>
        <LocaleSelector
          lastSelectedLocale={lastSelectedLocale}
          setLastSelectedLocale={setLastSelectedLocale}
        />
      </DropdownMenuItemSelect>
    </>
  );
};
