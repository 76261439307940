import { LinkList } from "../shared/LinkList";
import { LinkItemIcon } from "../shared/ListItemIcon";
import { ViewMoreLink } from "../shared/ViewMoreLink";
import React from "react";
import { useTranslations } from "../../../helpers/translations";
import { SubMenuFooter } from "../shared/SubMenuFooter";
import { IconArrowRight } from "@tabler/icons-react";

type ApplicationSubMenuProps = {
  compact?: boolean;
  wrapperComponent: React.ComponentType<{
    title: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
  }>;
};

export const ApplicationSubMenu = ({
  compact,
  wrapperComponent,
}: ApplicationSubMenuProps) => {
  const t = useTranslations("nav.application");

  const WrapperComponent = wrapperComponent;

  return (
    <WrapperComponent
      title={t("title")}
      footer={
        <SubMenuFooter
          compact={compact}
          singleLink={
            <ViewMoreLink
              href={t("view_more.href")}
              text={t("view_more.text")}
            />
          }
        />
      }
    >
      <LinkList
        heading={t("col1.header")}
        items={[
          {
            title: t("col1.palletizing.title"),
            href: t("col1.palletizing.href"),
            icon: <LinkItemIcon src={t("col1.palletizing.icon")} alt="icon" />,
            subtitle: t("col1.palletizing.subtitle"),
          },
          {
            title: t("col1.sanding.title"),
            href: t("col1.sanding.href"),
            icon: <LinkItemIcon src={t("col1.sanding.icon")} alt="icon" />,
            subtitle: t("col1.sanding.subtitle"),
          },
        ]}
      />

      <LinkList
        heading={t("col2.header")}
        items={[
          {
            title: t("col2.welding.title"),
            href: t("col2.welding.href"),
            icon: <LinkItemIcon src={t("col2.welding.icon")} alt="icon" />,
            subtitle: t("col2.welding.subtitle"),
          },
          {
            title: t("col2.tending.title"),
            href: t("col2.tending.href"),
            icon: <LinkItemIcon src={t("col2.tending.icon")} alt="icon" />,
            subtitle: t("col2.tending.subtitle"),
          },
        ]}
      />
    </WrapperComponent>
  );
};
