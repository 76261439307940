import React from "react";

export default function VentionLogo({
  className,
}: {
  readonly className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 867.47 94.32"
      className={className}
    >
      <g>
        <path
          d="M77.25,54.54c1.38-1.62,2.51-2.9,3.6-4.22q18-21.79,36.06-43.59C121.33,1.4,127-.5,133.7,1.41S144.13,8,145.13,14.84a14.42,14.42,0,0,1-3.38,11.54C124.52,47.31,107.32,68.25,89.93,89a16.51,16.51,0,0,1-23.26,2.15A16.8,16.8,0,0,1,64.52,89Q38.41,57.88,12.72,26.38A15.37,15.37,0,0,1,15.16,4.32C22-1.22,31.62-.38,37.55,6.72,49.72,21.29,61.76,36,73.86,50.6,74.87,51.81,75.91,53,77.25,54.54Zm6.64,21.18A6.88,6.88,0,0,0,77,68.83h0a6.8,6.8,0,0,0-6.92,6.68v.12A6.91,6.91,0,0,0,77,82.56a7,7,0,0,0,6.89-6.84Zm52-59.3a6.84,6.84,0,0,0-7-6.72h0a6.87,6.87,0,0,0-6.7,7,6.75,6.75,0,0,0,6.86,6.66h.14a6.7,6.7,0,0,0,6.66-6.74v-.2ZM25.65,9.7a6.86,6.86,0,0,0-7,6.7v.07A6.75,6.75,0,0,0,25.2,23.4h.19a6.77,6.77,0,0,0,7-6.54v-.17a6.9,6.9,0,0,0-6.78-7Z"
          transform="translate(-9.13 -0.69)"
        />
        <path
          d="M432.1,43.14V87.92H418V17.47c7.26-2.28,11.67-1,16.26,4.85L462.39,57.9c.84,1.07,1.71,2.12,3.23,4V17h14.11V87.86c-6.58.89-12.13.8-16.76-5.63-8.72-12.15-18.45-23.62-27.72-35.37C434.45,45.9,433.63,45,432.1,43.14Z"
          transform="translate(-9.13 -0.69)"
        />
        <path
          d="M862.6,61.86V17.06h14V87.57c-7.62,2.17-11.64.92-16.35-5L831.86,46.72c-.73-.92-1.53-1.8-3-3.5V88h-14V17.13c6.49-.82,12.06-1,16.72,5.51,8.79,12.28,18.58,23.85,28,35.7C860.25,59.25,861.12,60.16,862.6,61.86Z"
          transform="translate(-9.13 -0.69)"
        />
        <path
          d="M694.43,52.25C694.52,31.11,710,16,731.55,16.06s37.11,15.43,37,36.75c-.11,21.11-15.66,36.25-37.17,36.18C709.69,88.89,694.34,73.63,694.43,52.25Zm57.41,0c-.33-2.37-.5-5.13-1.13-7.78-2.18-9.22-8.88-14.47-18.62-14.79C722.56,29.36,715.17,34,712.78,43a43.14,43.14,0,0,0-1,14.76c1,11,8.32,17.37,19.1,17.66,11,.29,18.17-5.53,20.23-16.43.36-2.15.45-4.28.73-6.77Z"
          transform="translate(-9.13 -0.69)"
        />
        <path
          d="M357,46.33V58.25H335v17.3H364.1c0,4,.1,7.44-.08,10.9a2.54,2.54,0,0,1-1.85,1.67c-14.47.1-28.94.08-43.71.08V17c1.24-.09,2.33-.23,3.42-.23,12.87,0,25.75.05,38.62-.07,2.69,0,3.93.58,3.69,3.52s-.05,5.7-.05,9.09H351.58c-4.75,0-9.5-.12-14.24.1a3.39,3.39,0,0,0-2.47,2.17c-.25,4.11-.05,8.24-.14,12.36,0,2.51,1.59,2.37,3.27,2.37Z"
          transform="translate(-9.13 -0.69)"
        />
        <path
          d="M201.1,16.9c5.18,0,9.8-.39,14.3.2,1.7.23,3.87,2.42,4.53,4.2,5.56,14.84,10.81,29.79,16.15,44.71.33.9.68,1.8,1.11,2.93,5-14,9.9-27.74,14.76-41.46C256,16,255.07,16.75,266.83,16.79H273c-.6,1.67-1,2.9-1.48,4.11C263,42.21,254.42,63.51,246,84.9c-1,2.42-2,3.66-4.82,3.42-3.72-.32-8.34,1.09-11-.66s-3.32-6.44-4.71-9.89C217.42,57.69,209.41,37.65,201.1,16.9Z"
          transform="translate(-9.13 -0.69)"
        />
        <path
          d="M564.8,87.94H548.41V30h-20V17.12h56.14V29.75H564.8Z"
          transform="translate(-9.13 -0.69)"
        />
        <path
          d="M629.54,17H645.4V88H629.54Z"
          transform="translate(-9.13 -0.69)"
        />
      </g>
    </svg>
  );
}
