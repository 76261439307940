import {
  NavigationMenu,
  NavigationMenuList,
} from "@/components/ui/NavigationMenu";
import { DesktopSubMenu } from "./shared/DesktopSubMenu";
import { ApplicationSubMenu } from "./subnav/ApplicationSubMenu";
import { CommunitySubMenu } from "./subnav/CommunitySubMenu";
import { PlatformSubMenu } from "./subnav/PlatformSubMenu";
import { ResourcesSubMenu } from "./subnav/ResourcesSubMenu";
import { ShopSubMenu } from "./subnav/ShopSubMenu";
import { SupportSubMenu } from "./subnav/SupportSubMenu";
import React from "react";

export const DesktopNavigation = ({
  className,
}: {
  readonly className?: string;
}) => (
  <NavigationMenu delayDuration={50} className={className}>
    <NavigationMenuList>
      <PlatformSubMenu wrapperComponent={DesktopSubMenu} />
      <ApplicationSubMenu wrapperComponent={DesktopSubMenu} />
      <ShopSubMenu wrapperComponent={DesktopSubMenu} />
      <ResourcesSubMenu wrapperComponent={DesktopSubMenu} />
      <CommunitySubMenu wrapperComponent={DesktopSubMenu} />
      <SupportSubMenu />
    </NavigationMenuList>
  </NavigationMenu>
);
