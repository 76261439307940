import {
  NavigationMenuItem,
  navigationMenuTriggerStyle,
} from "@/components/ui/NavigationMenu";
import { cn } from "@/lib/utils";
import React from "react";

interface PlainLinkProps {
  href: string;
  title: string;
}

export const PlainLink = ({ href, title }: PlainLinkProps) => (
  <NavigationMenuItem key={href}>
    <a
      href={href}
      className={cn(
        "main-navigation-menu-trigger",
        navigationMenuTriggerStyle(),
      )}
    >
      {title}
    </a>
  </NavigationMenuItem>
);
