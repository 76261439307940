import React, { ReactNode, createContext, useContext } from "react";

interface PublicConfig {
  RAILS_ROOT_URL: string;
  GRAPHQL_PUBLIC_API_URL: string;
  GRAPHQL_PRIVATE_API_URL: string;
  RECAPTCHA_SITE_KEY?: string;
  COVEO_ORGANIZATION_ID?: string;
  LOCALIZED_IN?: string[]; // custom config for rails
}

interface PublicConfigProviderProps {
  children: ReactNode;
  config: PublicConfig;
}

// Create a context with a generic type
const PublicConfigContext = createContext<PublicConfig | undefined>(undefined);

export const PublicConfigProvider = ({
  children,
  config,
}: PublicConfigProviderProps) => {
  return (
    <PublicConfigContext.Provider value={config}>
      {children}
    </PublicConfigContext.Provider>
  );
};

export const usePublicConfigContext = () => {
  const context = useContext(PublicConfigContext);
  if (context === undefined) {
    throw new Error(
      "usePublicConfigContext must be used within a PublicConfigProvider",
    );
  }
  return context;
};
