import React from "react";

interface ListItemTextProps {
  children: React.ReactNode;
}

export const LinkItemTitle = ({ children }: ListItemTextProps) => (
  <span className="text-md-semibold leading-none pb-xs block">{children}</span>
);

export const LinkItemSubtitle = ({ children }: ListItemTextProps) => (
  <span className="line-clamp-2 text-sm-regular leading-snug text-muted-foreground">
    {children}
  </span>
);
