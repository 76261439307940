import { Icon, IconProps } from "@tabler/icons-react";
import React from "react";

export const DropdownMenuItemIcon = React.forwardRef<
  Icon,
  {
    size?: number;
    icon: React.ForwardRefExoticComponent<
      IconProps & React.RefAttributes<Icon>
    >;
  }
>(({ icon, size }, ref) => {
  const IconComponent = icon;

  return (
    <IconComponent
      className="text-quaternary"
      ref={ref}
      width={size}
      height={size}
    />
  );
});

DropdownMenuItemIcon.displayName = "DropdownMenuItemIcon";
