import { useQuery, UseQueryResult } from "@tanstack/react-query";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useGraphQLContext } from "./GraphQLProvider";
import { CoveoJwtCreateMutationDTO } from "../services/graphql/coveo/coveo.mutations";
import { CoveoService } from "../services/graphql/coveo/CoveoService";

type CoveoContext = {
  jwtCreateGlobalSearch: UseQueryResult<CoveoJwtCreateMutationDTO, Error>;
  jwtRenewGlobalSearch: () => Promise<string | null>;
};

type CoveoProviderProps = {
  children: ReactNode;
};

const CoveoContext = createContext<CoveoContext | undefined>(undefined);

export const CoveoProvider = ({ children }: CoveoProviderProps) => {
  const { publicClient } = useGraphQLContext();
  const coveoService = CoveoService({ publicClient });

  const jwtCreateGlobalSearch = useQuery<CoveoJwtCreateMutationDTO>({
    queryKey: ["jwtCreateGlobalSearch"],
    queryFn: coveoService.mutateJwtCreateGlobalSearch,
  });

  const jwtRenewGlobalSearch = useCallback(async () => {
    const response = await jwtCreateGlobalSearch.refetch();
    return response.data?.coveoJwtCreate.jwt ?? null;
  }, [jwtCreateGlobalSearch]);

  const memoizedValue = useMemo(
    () => ({ jwtCreateGlobalSearch, jwtRenewGlobalSearch }),
    [jwtCreateGlobalSearch, jwtRenewGlobalSearch],
  );

  return (
    <CoveoContext.Provider value={memoizedValue}>
      {children}
    </CoveoContext.Provider>
  );
};

export const useCoveoContext = () => {
  const context = useContext(CoveoContext);
  if (context === undefined) {
    throw new Error("useCoveoContext must be used within a CoveoProvider");
  }
  return context;
};
