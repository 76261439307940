import React from "react";
import { GraphQLProvider } from "../providers/GraphQLProvider";
import { UserProvider } from "../providers/UserProvider";
import { CoveoProvider } from "../providers/CoveoProvider";
import { PublicConfigProvider } from "../providers/PublicConfigProvider";
import { ReactQueryProvider } from "../providers/ReactQueryProvider";
import { MainNavigation } from "../components/nav/MainNavigation";

type MainNavigationWrapperProps = {
  readonly config: {
    readonly RAILS_ROOT_URL: string;
    readonly COVEO_ORGANIZATION_ID: string;
    readonly GRAPHQL_PUBLIC_API_URL: string;
    readonly GRAPHQL_PRIVATE_API_URL: string;
    readonly LOCALIZED_IN?: string[];
  };
  readonly locale: string;
};

export default function MainNavigationWrapper({
  config,
  locale,
}: MainNavigationWrapperProps) {
  return (
    <ReactQueryProvider>
      <PublicConfigProvider config={config}>
        <GraphQLProvider>
          <UserProvider locale={locale}>
            <CoveoProvider>
              <MainNavigation />
            </CoveoProvider>
          </UserProvider>
        </GraphQLProvider>
      </PublicConfigProvider>
    </ReactQueryProvider>
  );
}
