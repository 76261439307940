import React from "react";

export default function VentionLogoSmall({
  className,
}: {
  readonly className?: string;
}) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>vention-logo-sm</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2474 7.30774L28.2915 7.34436C29.6159 8.45585 29.8023 10.4522 28.707 11.7953L18.5357 24.2707C17.9416 24.9993 17.0655 25.4173 16.133 25.4173C16.1039 25.4173 16.0751 25.4155 16.0464 25.4137C16.0309 25.4128 16.0153 25.4118 15.9998 25.4111C15.9851 25.4118 15.9704 25.4127 15.9557 25.4136C15.927 25.4153 15.8983 25.417 15.8695 25.417C14.9363 25.417 14.0603 24.9993 13.4665 24.2707L3.29485 11.7953C2.76416 11.1443 2.51521 10.3233 2.59382 9.4828C2.6723 8.64232 3.06875 7.88287 3.71023 7.34472L3.75522 7.30702C4.3123 6.84007 5.017 6.58252 5.73934 6.58252C6.67251 6.58252 7.54815 7.00024 8.14228 7.72907L16.0012 17.3677L23.8595 7.72907C24.4539 7.00048 25.3295 6.58276 26.2627 6.58276C26.985 6.58276 27.6896 6.84007 28.2474 7.30774ZM5.80364 11.1491C5.05763 11.1491 4.45296 10.5354 4.45296 9.77889C4.45296 9.02221 5.05763 8.40901 5.80364 8.40901C6.54954 8.40901 7.15409 9.02221 7.15409 9.77889C7.15409 10.5354 6.54954 11.1491 5.80364 11.1491ZM24.8627 9.77889C24.8627 10.5354 25.4678 11.1491 26.2133 11.1491C26.9591 11.1491 27.5639 10.5354 27.5639 9.77889C27.5639 9.02221 26.9591 8.40901 26.2133 8.40901C25.4678 8.40901 24.8627 9.02221 24.8627 9.77889ZM14.5959 21.591C14.5959 22.3476 15.2007 22.9608 15.9466 22.9608C16.6924 22.9608 17.297 22.3476 17.297 21.591C17.297 20.8344 16.6924 20.2211 15.9466 20.2211C15.2007 20.2211 14.5959 20.8344 14.5959 21.591Z"
      />
    </svg>
  );
}
