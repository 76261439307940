import React from "react";

const YoutubeLogo = () => {
  return (
    <svg
      viewBox="0 0 25 18"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8 md:w-6 md:h-6"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6685 0C20.7285 0 22.3308 0.447677 23.1986 1.54517C24.0705 2.64753 24.1357 4.33602 24.1357 6.78603V11.214C24.1357 14.0216 23.7195 15.6432 22.7439 16.6364C21.7927 17.605 20.3222 18 17.6686 18H6.56074C1.0939 18 0.09375 15.7148 0.09375 11.214V6.78603C0.09375 4.46283 0.09375 2.78425 0.936693 1.645C1.80127 0.476555 3.4307 0 6.56074 0H17.6685ZM10.4854 12.416L15.5294 9.61156C15.7857 9.46916 15.9461 9.1869 15.9458 8.87916C15.9454 8.57166 15.7843 8.28981 15.5277 8.14807L10.4836 5.36168C10.2433 5.22885 9.95516 5.23908 9.7235 5.38874C9.49192 5.53841 9.35062 5.80573 9.35062 6.09384V11.6848C9.35062 11.9734 9.49231 12.2409 9.72435 12.3905C9.84759 12.4699 9.98672 12.5098 10.1262 12.5098C10.2493 12.5098 10.3726 12.4787 10.4854 12.416Z"
        fill="inherit"
      />
    </svg>
  );
};

export default YoutubeLogo;
