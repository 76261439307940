import I18n from "../../../../helpers/I18n";

// just a helper function to avoid repeating the same prefix
// and keeping the same signature as the useTranslations hook in nextjs
export function useTranslations(prefix: string) {
  return (translationKey: string) =>
    I18n.t(`views.next_js.${prefix}.${translationKey}`);
}

// another helper function to get current locale
export function useLocale() {
  return I18n.locale;
}
