import { cn } from "@/lib/utils";
import { LinkList } from "../shared/LinkList";
import { LinkItemIcon } from "../shared/ListItemIcon";
import { LinkItemSubtitle, LinkItemTitle } from "../shared/ListItemText";
import { SubMenuHeading } from "../shared/SubHeading";
import { SubMenuImage } from "../shared/SubMenuImage";
import { ViewMoreLink } from "../shared/ViewMoreLink";
import React from "react";
import { useTranslations } from "../../../helpers/translations";

type CommunitySubMenuProps = {
  compact?: boolean;
  wrapperComponent: React.ComponentType<{
    title: string;
    children: React.ReactNode;
    noPadding?: boolean;
  }>;
};

export const CommunitySubMenu = ({
  wrapperComponent,
  compact,
}: CommunitySubMenuProps) => {
  const t = useTranslations("nav.community");

  const WrapperComponent = wrapperComponent;

  return (
    <WrapperComponent title={t("title")} noPadding>
      <section className={cn({ "p-4xl": !compact })}>
        <LinkList
          heading={t("col1.header")}
          items={[
            {
              title: t("col1.links.customer_stories.title"),
              href: t("col1.links.customer_stories.href"),
              subtitle: t("col1.links.customer_stories.subtitle"),
              icon: (
                <LinkItemIcon
                  src={t("col1.links.customer_stories.icon")}
                  alt="icon"
                />
              ),
            },
            {
              title: t("col1.links.events.title"),
              href: t("col1.links.events.href"),
              subtitle: t("col1.links.events.subtitle"),
              icon: (
                <LinkItemIcon src={t("col1.links.events.icon")} alt="icon" />
              ),
            },
            {
              title: t("col1.links.webinars.title"),
              href: t("col1.links.webinars.href"),
              subtitle: t("col1.links.webinars.subtitle"),
              icon: (
                <LinkItemIcon src={t("col1.links.webinars.icon")} alt="icon" />
              ),
            },
            {
              title: t("col1.links.community_designs.title"),
              href: t("col1.links.community_designs.href"),
              subtitle: t("col1.links.community_designs.subtitle"),
              icon: (
                <LinkItemIcon
                  src={t("col1.links.community_designs.icon")}
                  alt="icon"
                />
              ),
            },
          ]}
        />
      </section>
      <section className={cn({ "bg-gray-50 p-4xl": !compact })}>
        <SubMenuHeading>{t("col2.header")}</SubMenuHeading>
        <a href={t("col2.view_more.href")}>
          <SubMenuImage
            src={t("col2.image")}
            alt={t("col2.title")}
            width={342}
            height={210}
            className="rounded-md"
          />
        </a>
        <LinkItemTitle>{t("col2.title")}</LinkItemTitle>
        <LinkItemSubtitle>{t("col2.subtitle")}</LinkItemSubtitle>
        <ViewMoreLink
          className="mt-4xl"
          href={t("col2.view_more.href")}
          text={t("col2.view_more.text")}
        />
      </section>
    </WrapperComponent>
  );
};
