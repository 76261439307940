import { IconArrowRight, IconMessage2 } from "@tabler/icons-react";
import { FooterLinkList } from "./FooterLinkList";
import { useTranslations } from "../helpers/translations";
import { Button } from "@/components/ui/button";
import React from "react";
import VentionLogo from "./core/VentionLogo";
import { RegionLocaleSelector } from "./nav/utility-links/user-menu/RegionLocaleSelector";

export const Footer = ({
  contactPhoneNumber,
}: {
  contactPhoneNumber: string;
}) => {
  const t = useTranslations("footer");

  const platformLinks = {
    title: t("platform.title"),
    links: [
      { href: "/machine-scope", label: t("platform.links.machine_scope") },
      { href: "/machine-builder", label: t("platform.links.machine_builder") },
      { href: "/machine-logic", label: t("platform.links.machine_logic") },
      {
        href: "/machine-applications",
        label: t("platform.links.machine_apps"),
      },
      { href: "/machine-portal", label: t("platform.links.machine_portal") },
      { href: "/machine-cloud", label: t("platform.links.machine_cloud") },
    ],
  };

  const hardwareTechnologyLinks = {
    title: t("hardware_technology.title"),
    links: [
      {
        href: "/hardware",
        label: t("hardware_technology.links.machine_frame"),
      },
      {
        href: "/machinemotion-v2",
        label: t("hardware_technology.links.machine_motion"),
      },
      { href: "/actuators", label: t("hardware_technology.links.actuators") },
    ],
  };

  const applicationLinks = {
    title: t("applications.title"),
    links: [
      {
        href: "/applications",
        label: t("applications.links.browse_all_applications"),
      },
      {
        href: "/applications#assembly",
        label: t("applications.links.assembly"),
      },
      {
        href: "/applications#manufacturing",
        label: t("applications.links.manufacturing"),
      },
      {
        href: "/applications#material-handling",
        label: t("applications.links.material_handling"),
      },
      {
        href: "/applications#packaging",
        label: t("applications.links.packaging"),
      },
    ],
  };

  const hardwareLibraryLinks = {
    title: t("hardware_library.title"),
    links: [
      {
        href: "/parts-library",
        label: t("hardware_library.links.browse_all_hardware"),
      },
    ],
  };

  const designLibraryLinks = {
    title: t("design_library.title"),
    links: [
      {
        href: "/designs/overview",
        label: t("design_library.links.browse_all_designs"),
      },
    ],
  };

  const resourcesLinks = {
    title: t("resources.title"),
    links: [
      { href: "/resources", label: t("resources.links.resource_center") },
      { href: "/services", label: t("resources.links.service_and_support") },
      {
        href: "/customer-stories",
        label: t("resources.links.customer_stories"),
      },
      {
        href: "/resources/video-tutorials",
        label: t("resources.links.video_tutorials"),
      },
      { href: "/webinars", label: t("resources.links.webinars") },
      { href: "/blogs", label: t("resources.links.blog") },
      { href: "/resources/faqs", label: t("resources.links.faq") },
    ],
  };

  const toolsLinks = {
    title: t("tools.title"),
    links: [{ href: "/tools", label: t("tools.links.browse_tools") }],
  };

  const communityLinks = {
    title: t("community.title"),
    links: [
      { href: "/community", label: t("community.links.community") },
      { href: "/leaderboard", label: t("community.links.top_designers") },
      { href: "/community-connect", label: t("community.links.forum") },
      { href: "/gallery", label: t("community.links.gallery") },
    ],
  };

  const connectLinks = {
    title: t("connect.title"),
    links: [
      { href: "/about", label: t("connect.links.about_us") },
      { href: "/press", label: t("connect.links.press") },
      { href: "/contact", label: t("connect.links.contact_us") },
      {
        href: "/careers",
        label: t("connect.links.careers"),
        badge: t("were_hiring"),
      },
      {
        href: "/vention-automation-partner",
        label: t("connect.links.partners"),
      },
      {
        href: "/marketplace-program",
        label: t("connect.links.marketplace_program"),
      },
    ],
  };

  return (
    <div className="bg-gray-light-800">
      <div className="container py-6xl text-xs-regular text-white space-y-7xl sm:flex sm:justify-between sm:space-x-[5%] sm:space-y-none">
        <div className="space-y-4xl col-span-2 grow max-w-xs">
          <div>
            <a href={"/"} className="w-44 block mb-xl">
              <VentionLogo className="fill-white" />
            </a>
            <div>{t("sub_logo_text")}</div>
          </div>
          <div>
            <RegionLocaleSelector
              labelClassName="text-xs-medium mb-md text-gray-500"
              triggerClassName="text-sm-semibold px-lg py-sm h-auto mb-xl max-w-[200px]"
            />
          </div>
          <div>
            <div className="mb-2 font-medium text-gray-500">{t("contact")}</div>
            <div className="mb-4">
              {t("email")}
              <a
                href="mailto:info@vention.cc"
                className="no-underline text-white ml-xs hover:text-gray-200 focus:text-gray-200 hover:no-underline"
              >
                info@vention.cc
              </a>
            </div>
            <div>
              {t("call")}
              <a
                href={`tel:${contactPhoneNumber}`}
                className="no-underline text-white ml-xs hover:text-gray-200 focus:text-gray-200 hover:no-underline"
              >
                {contactPhoneNumber}
              </a>
            </div>
          </div>
          <div>
            <div className="mb-xl">
              <a href={"/contact/new"} className="block">
                <Button
                  size="xl"
                  className="bg-brand-500 w-full text-md-semibold"
                >
                  {t("book_a_demo")}
                  <IconArrowRight className="ml-md" size={20} />
                </Button>
              </a>
            </div>
            <div>
              <a href={"/contact"} className="block">
                <Button
                  size="xl"
                  variant={"secondaryGray"}
                  className="w-full text-md-semibold"
                >
                  {t("support_button")}
                  <IconMessage2 className="ml-md" size={20} />
                </Button>
              </a>
            </div>
          </div>
          <div>
            <div className="flex mb-md">
              <img
                src="https://assets.vention.io/svg/icons/shield-check.svg"
                alt="Security shield"
                className="mr-xs"
              />
              {t("compliance")}
            </div>
            <p>ISO 27001 & NIST</p>
          </div>
        </div>
        <div className="grow lg:flex lg:justify-between grid grid-cols-2">
          <div className="mr-xl text-vention-grey">
            <FooterLinkList {...platformLinks} />
            <FooterLinkList {...hardwareTechnologyLinks} />
          </div>
          <div className="mr-xl text-vention-grey">
            <FooterLinkList {...applicationLinks} />
            <FooterLinkList {...hardwareLibraryLinks} />
            <FooterLinkList {...designLibraryLinks} />
          </div>
          <div className="mr-xl text-vention-grey">
            <FooterLinkList {...resourcesLinks} />
            <FooterLinkList {...toolsLinks} />
          </div>
          <div className="mr-xl text-vention-grey">
            <FooterLinkList {...communityLinks} />
            <FooterLinkList {...connectLinks} />
          </div>
        </div>
      </div>
    </div>
  );
};
