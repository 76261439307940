import { useTranslations } from "../../../helpers/translations";
import { LinkList } from "../shared/LinkList";
import { LinkItemIcon } from "../shared/ListItemIcon";
import React from "react";

type ResourcesSubMenuProps = {
  wrapperComponent: React.ComponentType<{
    title: string;
    children: React.ReactNode;
  }>;
};

export const ResourcesSubMenu = ({
  wrapperComponent,
}: ResourcesSubMenuProps) => {
  const t = useTranslations("nav.resources");

  const WrapperComponent = wrapperComponent;

  return (
    <WrapperComponent title={t("title")}>
      <LinkList
        heading={t("col1.header")}
        items={[
          {
            title: t("col1.links.documentation.title"),
            href: t("col1.links.documentation.href"),
            subtitle: t("col1.links.documentation.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col1.links.documentation.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col1.links.video_tutorials.title"),
            href: t("col1.links.video_tutorials.href"),
            subtitle: t("col1.links.video_tutorials.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col1.links.video_tutorials.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col1.links.engineering_tools.title"),
            href: t("col1.links.engineering_tools.href"),
            subtitle: t("col1.links.engineering_tools.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col1.links.engineering_tools.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col1.links.blog.title"),
            href: t("col1.links.blog.href"),
            subtitle: t("col1.links.blog.subtitle"),
            icon: <LinkItemIcon src={t("col1.links.blog.icon")} alt="icon" />,
          },
        ]}
      />
      <LinkList
        heading={t("col2.header")}
        items={[
          {
            title: t("col2.links.experience_center.title"),
            href: t("col2.links.experience_center.href"),
            subtitle: t("col2.links.experience_center.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col2.links.experience_center.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col2.links.services_training.title"),
            href: t("col2.links.services_training.href"),
            subtitle: t("col2.links.services_training.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col2.links.services_training.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col2.links.faqs.title"),
            href: t("col2.links.faqs.href"),
            subtitle: t("col2.links.faqs.subtitle"),
            icon: <LinkItemIcon src={t("col2.links.faqs.icon")} alt="icon" />,
          },
          {
            title: t("col2.links.automation_partner.title"),
            href: t("col2.links.automation_partner.href"),
            subtitle: t("col2.links.automation_partner.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col2.links.automation_partner.icon")}
                alt="icon"
              />
            ),
          },
        ]}
      />
    </WrapperComponent>
  );
};
