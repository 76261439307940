import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import React from "react";

export interface FooterLinkProps {
  href: string;
  label: string;
  badge?: string;
}

export const FooterLink = ({ href, label, badge }: FooterLinkProps) => {
  return (
    <li className="mb-4">
      <a
        href={href}
        className="no-underline text-white hover:text-gray-200 hover:no-underline"
      >
        <span
          className={cn({
            "mr-md": !!badge,
          })}
        >
          {label}
        </span>
        {badge && (
          <Badge className="bg-brand-650 border-none rounded text-xs-regular font-normal text-vention-grey hover:text-vention-grey">
            {badge}
          </Badge>
        )}
      </a>
    </li>
  );
};
