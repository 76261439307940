import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import React from "react";

export const DropdownMenuItemSelect = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>(({ children, ...props }, ref) => {
  return (
    <DropdownMenuItem asChild className="block focus:bg-white hover:bg-white">
      <div
        {...props}
        ref={ref}
        className={cn(
          "w-full text-secondary text-left py-lg px-lg",
          props.className,
        )}
        onClick={(e) => e.preventDefault()}
      >
        {children}
      </div>
    </DropdownMenuItem>
  );
});

DropdownMenuItemSelect.displayName = "DropdownMenuItemSelect";
