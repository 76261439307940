import { IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useUserContext } from "../providers/UserProvider";
import { Button } from "@/components/ui/button";

export const MainHeaderBanner: React.FC = () => {
  const { userHeaderBanner, dismissUserHeaderBanner } = useUserContext();

  const headerBanner = userHeaderBanner.data?.userHeaderBanner?.entry;
  const userHeaderBannerVisible =
    userHeaderBanner.data?.userHeaderBanner?.dismissed === false;

  const [isBannerVisible, setIsBannerVisible] = useState(
    userHeaderBannerVisible,
  );

  useEffect(() => {
    setIsBannerVisible(userHeaderBannerVisible);
  }, [userHeaderBannerVisible, setIsBannerVisible]);

  if (!isBannerVisible || !headerBanner) {
    return null;
  }

  const closeBanner = () => {
    setIsBannerVisible(false);
    dismissUserHeaderBanner.mutate({ slug: headerBanner.slug });
  };

  return (
    <div className="py-md relative bg-success-600" id="main-header-banner">
      <div className="container flex justify-center items-center text-white text-sm-medium pr-6xl">
        {headerBanner.prefix}&nbsp;
        <span className="max-md:hidden">
          <span>{headerBanner.title}</span>&nbsp;
          <a
            href={headerBanner.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-light-200 focus:text-gray-light-200"
            data-analytic-id={`${headerBanner.slug}-link`}
          >
            {headerBanner.linkText}
          </a>
        </span>
        <a
          href={headerBanner.linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="md:hidden text-white hover:text-gray-light-200 focus:text-gray-light-200"
          data-analytic-id={`${headerBanner.slug}-mobile-link`}
        >
          {headerBanner.mobileLinkText || headerBanner.linkText}
        </a>
        <Button
          variant="noButton"
          size="icon"
          aria-label="Close banner"
          className="absolute right-[15px]"
          onClick={closeBanner}
        >
          <IconX />
        </Button>
      </div>
    </div>
  );
};
