import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import React from "react";

interface MobileSubMenuProps {
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

export const MobileSubMenu = ({
  title,
  children,
  footer,
  className,
}: MobileSubMenuProps) => {
  return (
    <AccordionItem key={title} value={`item-${title}`} className="border-none">
      <AccordionTrigger
        direction="right"
        className="appearance-none bg-transparent border-none w-full justify-between p-xl text-sm-semibold text-gray-light-600 hover:text-gray-light-700"
      >
        {title}
      </AccordionTrigger>
      <AccordionContent
        className={cn("flex flex-col p-xl space-y-4xl mb-4xl", className)}
      >
        {children}
        {footer}
      </AccordionContent>
    </AccordionItem>
  );
};
