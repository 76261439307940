import { GraphQLClient } from "graphql-request";
import React, { ReactNode, createContext, useContext, useMemo } from "react";
import { usePublicConfigContext } from "./PublicConfigProvider";

type GraphQLClients = {
  publicClient: GraphQLClient;
  privateClient: GraphQLClient;
};

type GraphQLProviderProps = {
  children: ReactNode;
};

const GraphQLContext = createContext<GraphQLClients | undefined>(undefined);

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  const { GRAPHQL_PUBLIC_API_URL, GRAPHQL_PRIVATE_API_URL } =
    usePublicConfigContext();

  const graphQlClients = useMemo(
    () => ({
      publicClient: new GraphQLClient(GRAPHQL_PUBLIC_API_URL),
      privateClient: new GraphQLClient(GRAPHQL_PRIVATE_API_URL),
    }),
    [GRAPHQL_PUBLIC_API_URL, GRAPHQL_PRIVATE_API_URL],
  );

  return (
    <GraphQLContext.Provider value={graphQlClients}>
      {children}
    </GraphQLContext.Provider>
  );
};

export const useGraphQLContext = () => {
  const context = useContext(GraphQLContext);
  if (context === undefined) {
    throw new Error("useGraphQLContext must be used within a GraphQLProvider");
  }
  return context;
};
