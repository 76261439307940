import { GraphQLClient } from 'graphql-request';
import {
  COVEO_JWT_CREATE_MUTATION,
  CoveoJwtCreateMutationDTO,
  CoveoJwtCreateMutationParams,
} from './coveo.mutations';

type CoveoServiceContext = {
  publicClient: GraphQLClient;
};

export type CoveoService = ReturnType<typeof CoveoService>;

export const CoveoService = (context: CoveoServiceContext) => ({
  mutateJwtCreateGlobalSearch: () =>
    context.publicClient.request<
      CoveoJwtCreateMutationDTO,
      CoveoJwtCreateMutationParams
    >(COVEO_JWT_CREATE_MUTATION, { searchHub: 'GlobalSearch' }),
});
