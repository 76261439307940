import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import React from "react";

export const DropdownMenuItemLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a">
>(({ children, ...props }, ref) => {
  return (
    <DropdownMenuItem asChild>
      <a
        {...props}
        ref={ref}
        className={cn(
          "no-underline w-full hover:no-underline flex items-center text-sm-medium text-secondary py-md px-lg space-x-md",
          props.className,
        )}
      >
        {children}
      </a>
    </DropdownMenuItem>
  );
});

DropdownMenuItemLink.displayName = "DropdownMenuItemLink";
