"use client";

import {
  IconChartPie3,
  IconChevronRight,
  IconCircleCheck,
  IconCircleX,
  IconCube,
  IconExternalLink,
  IconLayoutGrid,
  IconLogin2,
  IconSettings,
  IconShield,
  IconWallet,
  IconWorld,
} from "@tabler/icons-react";
import { cn } from "@/lib/utils";
import React, { Dispatch, SetStateAction, useState } from "react";
import { DropdownMenuItemIcon } from "./DropdownMenuItemIcon";
import { DropdownMenuItemLabel } from "./DropdownMenuItemLabel";
import { DropdownMenuItemLink } from "./DropdownMenuItemLink";
import { useTranslations } from "../../../../helpers/translations";
import {
  ADMIN_CHECKOUT_URL,
  ADMIN_DASHBOARD_URL,
  DASHBOARD_ANALYTICS_URL,
  DASHBOARD_DESIGNS_URL,
  DASHBOARD_ORDERS_URL,
  DASHBOARD_SETTINGS_URL,
  DASHBOARD_URL,
  USER_SESSION_LOGIN_URL,
} from "../../../../helpers/legacyRoutes";
import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { useUserContext } from "../../../../providers/UserProvider";

interface MainMenuContentProps {
  setOpenedMenu: Dispatch<SetStateAction<"main" | "location">>;
}

export const MainMenuContent = (props: MainMenuContentProps) => {
  const { setOpenedMenu } = props;

  const { currentUserAll, toggleUserAdmin, logoutUser } = useUserContext();

  const user = currentUserAll.data?.me;
  const isLoggedIn = !!user;

  const [adminOn, setAdminOn] = useState<boolean>(user?.adminOn || false);

  const t = useTranslations("nav.userMenu");

  return (
    <>
      {isLoggedIn && (
        <>
          <DropdownMenuItemLink href={DASHBOARD_URL}>
            <DropdownMenuItemIcon icon={IconLayoutGrid} size={16} />
            <DropdownMenuItemLabel>{t("dashboard")}</DropdownMenuItemLabel>
          </DropdownMenuItemLink>

          <DropdownMenuItemLink href={DASHBOARD_DESIGNS_URL}>
            <DropdownMenuItemIcon icon={IconCube} size={16} />
            <DropdownMenuItemLabel>{t("designs")}</DropdownMenuItemLabel>
          </DropdownMenuItemLink>

          <DropdownMenuItemLink href={DASHBOARD_ORDERS_URL}>
            <DropdownMenuItemIcon icon={IconWallet} size={16} />
            <DropdownMenuItemLabel>{t("orders")}</DropdownMenuItemLabel>
          </DropdownMenuItemLink>

          <DropdownMenuItemLink href={DASHBOARD_ANALYTICS_URL}>
            <DropdownMenuItemIcon icon={IconChartPie3} size={16} />
            <DropdownMenuItemLabel>{t("analytics")}</DropdownMenuItemLabel>
          </DropdownMenuItemLink>
          <DropdownMenuSeparator />

          <DropdownMenuItemLink href={DASHBOARD_SETTINGS_URL}>
            <DropdownMenuItemIcon icon={IconSettings} size={16} />
            <DropdownMenuItemLabel>{t("settings")}</DropdownMenuItemLabel>
          </DropdownMenuItemLink>
        </>
      )}

      <>
        <DropdownMenuItemLink
          href="#"
          onClick={(event) => {
            setOpenedMenu("location");
            event.preventDefault();
          }}
          className="flex justify-between"
        >
          <div className="flex items-center space-x-md">
            <DropdownMenuItemIcon icon={IconWorld} size={16} />
            <DropdownMenuItemLabel>
              {t("localization.toggle")}
            </DropdownMenuItemLabel>
          </div>
          <DropdownMenuItemIcon icon={IconChevronRight} size={16} />
        </DropdownMenuItemLink>
      </>

      {user?.canAdmin && (
        <>
          <DropdownMenuSeparator />
          <DropdownMenuItemLink
            onClick={(event) => {
              event.preventDefault();
              toggleUserAdmin.mutate();
              setAdminOn((currentAdminModeOn) => !currentAdminModeOn);
            }}
            href="#"
          >
            <DropdownMenuItemIcon icon={IconShield} size={16} />
            <DropdownMenuItemLabel>
              {t("admin.toggle")}{" "}
              {adminOn ? (
                <IconCircleCheck className="ml-sm text-success-500" />
              ) : (
                <IconCircleX className="ml-sm text-error-500" />
              )}
            </DropdownMenuItemLabel>
          </DropdownMenuItemLink>

          {adminOn && (
            <DropdownMenuItemLink href={ADMIN_DASHBOARD_URL} target="_blank">
              <DropdownMenuItemIcon icon={IconExternalLink} size={16} />
              <DropdownMenuItemLabel>
                {t("admin.dashboard")}
              </DropdownMenuItemLabel>
            </DropdownMenuItemLink>
          )}

          {adminOn && (
            <DropdownMenuItemLink href={ADMIN_CHECKOUT_URL} target="_blank">
              <DropdownMenuItemIcon icon={IconExternalLink} size={16} />
              <DropdownMenuItemLabel>
                {t("admin.checkout")}
              </DropdownMenuItemLabel>
            </DropdownMenuItemLink>
          )}
        </>
      )}

      <DropdownMenuSeparator />

      {isLoggedIn && (
        <>
          <DropdownMenuItemLink
            className={cn({
              "opacity-50 pointer-events-none": logoutUser.isPending,
            })}
            onClick={(event) => {
              event.preventDefault();
              logoutUser.mutate({});
            }}
          >
            <DropdownMenuItemIcon icon={IconLogin2} size={16} />
            <DropdownMenuItemLabel>{t("logout")}</DropdownMenuItemLabel>
          </DropdownMenuItemLink>
        </>
      )}

      {!isLoggedIn && (
        <DropdownMenuItemLink href={USER_SESSION_LOGIN_URL}>
          <DropdownMenuItemIcon icon={IconLogin2} size={16} />
          <DropdownMenuItemLabel>{t("login")}</DropdownMenuItemLabel>
        </DropdownMenuItemLink>
      )}
    </>
  );
};
