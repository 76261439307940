import { useTranslations } from "../../../helpers/translations";
import { LinkItemSubtitle, LinkItemTitle } from "../shared/ListItemText";
import { SubMenuHeading } from "../shared/SubHeading";
import { SubMenuImage } from "../shared/SubMenuImage";
import { ViewMoreLink } from "../shared/ViewMoreLink";
import React from "react";

type ShopSubMenuProps = {
  compact?: boolean;
  wrapperComponent: React.ComponentType<{
    title: string;
    children: React.ReactNode;
  }>;
};

export const ShopSubMenu = ({
  wrapperComponent,
  compact,
}: ShopSubMenuProps) => {
  const t = useTranslations("nav.shop");

  const WrapperComponent = wrapperComponent;

  return (
    <WrapperComponent title={t("title")}>
      <section>
        <SubMenuHeading>{t("col1.header")}</SubMenuHeading>
        <a href={t("col1.view_more.href")}>
          <SubMenuImage
            src={t("col1.image")}
            alt={t("col1.title")}
            width={342}
            height={193}
            className="rounded-md"
          />
        </a>
        <LinkItemTitle>{t("col1.title")}</LinkItemTitle>
        <LinkItemSubtitle>{t("col1.subtitle")}</LinkItemSubtitle>
        <ViewMoreLink
          className="mt-4xl"
          href={t("col1.view_more.href")}
          text={t("col1.view_more.text")}
        />
      </section>
      <section>
        <SubMenuHeading compact={compact}>&nbsp;</SubMenuHeading>
        <a href={t("col2.view_more.href")}>
          <SubMenuImage
            src={t("col2.image")}
            alt={t("col2.title")}
            width={342}
            height={193}
            className="rounded-md"
          />
        </a>
        <LinkItemTitle>{t("col2.title")}</LinkItemTitle>
        <LinkItemSubtitle>{t("col2.subtitle")}</LinkItemSubtitle>
        <ViewMoreLink
          className="mt-4xl"
          href={t("col2.view_more.href")}
          text={t("col2.view_more.text")}
        />
      </section>
    </WrapperComponent>
  );
};
