"use client";

import { IconLoader2, IconUser } from "@tabler/icons-react";

import React, { useEffect, useState } from "react";
import { LocalizationMenuContent } from "./user-menu/LocalizationMenuContent";
import { MainMenuContent } from "./user-menu/MainMenuContent";
import { useUserContext } from "../../../providers/UserProvider";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { breakpoints } from "../../../helpers/window";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/Avatar";
import { cn } from "@/lib/utils";

export const UserMenu = () => {
  const { currentUserAll } = useUserContext();
  const user = currentUserAll.data?.me;
  const [opened, setOpened] = useState<boolean>(false);
  const [openedMenu, setOpenedMenu] = useState<"main" | "location">("main");
  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width < breakpoints.lg) {
      setOpened(false);
    }
  }, [width, setOpened]);

  const isLoggedIn = !!user;

  return (
    <DropdownMenu
      onOpenChange={(isOpen) => {
        if (isOpen) {
          setOpenedMenu("main");
        }
        setOpened(isOpen);
      }}
      modal={false}
      open={opened}
    >
      <DropdownMenuTrigger
        asChild={!isLoggedIn}
        id="user-menu-trigger"
        className={cn(
          "appearance-none bg-transparent border-none text-gray-400 hover:text-white focus:text-white cursor-pointer",
          {
            "p-md": isLoggedIn,
          },
        )}
      >
        {isLoggedIn ? (
          <Avatar>
            <AvatarImage src={user.avatarUrl} alt={user.displayName} />
            <AvatarFallback className="text-gray-400">
              <IconLoader2 width={32} className="animate-spin" />
            </AvatarFallback>
          </Avatar>
        ) : (
          <IconUser width={32} />
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-[240px]">
        {openedMenu === "main" ? (
          <MainMenuContent setOpenedMenu={setOpenedMenu} />
        ) : (
          <LocalizationMenuContent
            currentUserAll={currentUserAll}
            setOpenedMenu={setOpenedMenu}
          />
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
