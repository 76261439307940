import { cn } from "@/lib/utils";
import React from "react";
import { LinkItemSubtitle, LinkItemTitle } from "./ListItemText";

export const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & {
    icon?: React.ReactNode;
    href: string;
  }
>(({ className, title, icon, children, href, ...props }, ref) => {
  return (
    <li>
      <a
        ref={ref}
        className={cn(
          "flex select-none space-y-1 rounded-lg p-lg leading-none no-underline hover:no-underline outline-none transition-colors text-primary hover:bg-gray-50 focus:bg-gray-50",
          className,
        )}
        href={href}
        {...props}
      >
        {icon}
        <span>
          <LinkItemTitle>{title}</LinkItemTitle>
          <LinkItemSubtitle>{children}</LinkItemSubtitle>
        </span>
      </a>
    </li>
  );
});
ListItem.displayName = "ListItem";
