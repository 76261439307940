import { IconCoin } from "@tabler/icons-react";
import { LinkList } from "../shared/LinkList";
import { LinkItemIcon } from "../shared/ListItemIcon";
import { SubMenuFooter } from "../shared/SubMenuFooter";
import React from "react";
import { useTranslations } from "../../../helpers/translations";

type PlatformSubMenuProps = {
  compact?: boolean;
  wrapperComponent: React.ComponentType<{
    title: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
  }>;
};

export const PlatformSubMenu = ({
  wrapperComponent,
  compact,
}: PlatformSubMenuProps) => {
  const t = useTranslations("nav.platform");

  const WrapperComponent = wrapperComponent;

  return (
    <WrapperComponent
      title={t("title")}
      footer={
        <SubMenuFooter
          compact={compact}
          links={[
            {
              href: t("footer.href"),
              text: t("footer.text"),
              icon: <IconCoin className="inline-block w-5 h-5 mr-xs" />,
            },
          ]}
        />
      }
    >
      <LinkList
        heading={t("col1.header")}
        items={[
          {
            title: t("col1.links.machine_builder.title"),
            href: t("col1.links.machine_builder.href"),
            subtitle: t("col1.links.machine_builder.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col1.links.machine_builder.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col1.links.machine_logic.title"),
            href: t("col1.links.machine_logic.href"),
            subtitle: t("col1.links.machine_logic.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col1.links.machine_logic.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col1.links.machine_analytics.title"),
            href: t("col1.links.machine_analytics.href"),
            subtitle: t("col1.links.machine_analytics.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col1.links.machine_analytics.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col1.links.remote_support.title"),
            href: t("col1.links.remote_support.href"),
            subtitle: t("col1.links.remote_support.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col1.links.remote_support.icon")}
                alt="icon"
              />
            ),
          },
        ]}
        viewMore={{
          text: t("col1.view_more.text"),
          href: t("col1.view_more.href"),
        }}
      />
      <LinkList
        heading={t("col2.header")}
        items={[
          {
            title: t("col2.links.machine_motion_ai.title"),
            href: t("col2.links.machine_motion_ai.href"),
            subtitle: t("col2.links.machine_motion_ai.subtitle"),
            icon: (
              <LinkItemIcon
                src={t("col2.links.machine_motion_ai.icon")}
                alt="icon"
              />
            ),
          },
          {
            title: t("col2.links.actuators.title"),
            href: t("col2.links.actuators.href"),
            subtitle: t("col2.links.actuators.subtitle"),
            icon: (
              <LinkItemIcon src={t("col2.links.actuators.icon")} alt="icon" />
            ),
          },
          {
            title: t("col2.links.extrusions.title"),
            href: t("col2.links.extrusions.href"),
            subtitle: t("col2.links.extrusions.subtitle"),
            icon: (
              <LinkItemIcon src={t("col2.links.extrusions.icon")} alt="icon" />
            ),
          },
          {
            title: t("col2.links.conveyors.title"),
            href: t("col2.links.conveyors.href"),
            subtitle: t("col2.links.conveyors.subtitle"),
            icon: (
              <LinkItemIcon src={t("col2.links.conveyors.icon")} alt="icon" />
            ),
          },
        ]}
      />
    </WrapperComponent>
  );
};
