import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger,
} from "@/components/ui/NavigationMenu";
import { cn } from "@/lib/utils";
import React, { useEffect, useState } from "react";

interface DesktopSubMenuProps {
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  noPadding?: boolean;
  className?: string;
}

export const DesktopSubMenu = ({
  children,
  title,
  footer,
  noPadding,
  className,
}: DesktopSubMenuProps) => {
  // initially force mount the component for SEO purposes
  // (client component will be server side rendered and hydrated while being invisible)
  const [initialMount, setInitialMount] = useState<true | undefined>(true);

  useEffect(() => {
    setInitialMount(undefined);
  }, []);

  return (
    <NavigationMenuItem key={title} className="[&>div]:invisible">
      <NavigationMenuTrigger className="main-navigation-menu-trigger">
        {title}
      </NavigationMenuTrigger>
      <NavigationMenuContent forceMount={initialMount}>
        <div
          className={cn(
            "grid w-[780px] gap-4xl md:grid-cols-2",
            !noPadding && "p-4xl",
            footer && "pb-0",
            className,
          )}
        >
          {children}
        </div>
        {footer}
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};
