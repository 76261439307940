import { cn } from "@/lib/utils";
import { DesktopNavigation } from "./DesktopNavigation";
import { MobileNavigation } from "./MobileNavigation";
import { CartStatus } from "./utility-links/CartStatus";
import { UtilityLinks } from "./utility-links/UtilityLinks";
import React from "react";
import VentionLogoSmall from "../core/VentionLogoSmall";
import VentionLogo from "../core/VentionLogo";

export const MainNavigation = ({
  className,
}: {
  readonly className?: string;
}) => (
  <div className={cn("bg-gray-light-800", className)}>
    <div className="container h-[var(--header-nav-height)] flex justify-between items-center">
      <div className="flex flex-grow items-center">
        <a href="/?home" className="max-lg:hidden 2xl:hidden py-md">
          <VentionLogoSmall className="fill-white h-7" />
        </a>

        <a className="max-lg:flex max-2xl:hidden py-lg mt-[-3px]" href="/?home">
          <VentionLogo className="fill-white h-5 block" />
        </a>

        <DesktopNavigation
          // adjust desktop on specific breakpoints and language to make it fit responsive;
          // the nav is present on every page, so make it look pretty.
          className={cn(
            "max-lg:hidden",

            // german
            "[html[lang=de]_&_.main-navigation-menu-trigger]:px-lg",
            "max-[1270px]:[html[lang=de]_&_.main-navigation-menu-trigger]:px-md",
            "max-[1220px]:[html[lang=de]_&_.main-navigation-menu-trigger]:text-sm-bold",
            "max-[1190px]:[html[lang=de]_&>div>ul]:px-xl",
            "max-[1150px]:[html[lang=de]_&_.main-navigation-menu-trigger]:px-sm",
            "max-[1130px]:[html[lang=de]_&>div>ul]:px-lg",
            "max-[1120px]:[html[lang=de]_&_.main-navigation-menu-trigger]:px-xs",
            "max-[1100px]:[html[lang=de]_&>div>ul]:px-md",
            "max-[1090px]:[html[lang=de]_&_.main-navigation-menu-trigger]:px-xxs",

            // french
            "max-[1420px]:[html[lang=fr]_&_.main-navigation-menu-trigger]:px-lg",
            "max-[1240px]:[html[lang=fr]_&_.main-navigation-menu-trigger]:px-md",
            "max-[1180px]:[html[lang=fr]_&_.main-navigation-menu-trigger]:text-sm-bold",
            "max-[1160px]:[html[lang=fr]_&>div>ul]:px-xl",
            "max-[1120px]:[html[lang=fr]_&_.main-navigation-menu-trigger]:px-sm",
            "max-[1100px]:[html[lang=fr]_&>div>ul]:px-lg",
            "max-[1080px]:[html[lang=fr]_&_.main-navigation-menu-trigger]:px-xs",

            // english
            "max-[1200px]:[&_.main-navigation-menu-trigger]:px-lg",
            "max-[1140px]:[&_.main-navigation-menu-trigger]:px-md",
            "max-[1100px]:[&_.main-navigation-menu-trigger]:text-sm-bold",
          )}
        />
      </div>

      <div className="lg:hidden flex items-center">
        <CartStatus />
        <MobileNavigation />
      </div>

      <UtilityLinks className="max-lg:hidden h-[var(--header-nav-height)] overflow-x-hidden" />
    </div>
  </div>
);
