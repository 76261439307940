import React, { useEffect, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import GlobalSearchStandalone from "./global-search/GlobalSearchStandalone";
import { MobileSubMenu } from "./shared/MobileSubMenu";
import { ApplicationSubMenu } from "./subnav/ApplicationSubMenu";
import { CommunitySubMenu } from "./subnav/CommunitySubMenu";
import { PlatformSubMenu } from "./subnav/PlatformSubMenu";
import { ResourcesSubMenu } from "./subnav/ResourcesSubMenu";
import { ShopSubMenu } from "./subnav/ShopSubMenu";
import { usePublicConfigContext } from "../../providers/PublicConfigProvider";
import { useCoveoContext } from "../../providers/CoveoProvider";
import { useUserContext } from "../../providers/UserProvider";
import { breakpoints } from "../../helpers/window";
import { useTranslations } from "../../helpers/translations";
import { Accordion } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useWindowSize } from "../../hooks/useWindowSize";
import { RegionLocaleSelector } from "./utility-links/user-menu/RegionLocaleSelector";

export const MobileNavigation = () => {
  const [opened, setOpened] = useState(false);
  const { width } = useWindowSize();

  const t = useTranslations("nav");

  const { COVEO_ORGANIZATION_ID } = usePublicConfigContext();
  const { jwtCreateGlobalSearch, jwtRenewGlobalSearch } = useCoveoContext();

  const { currentUserAll } = useUserContext();
  const user = currentUserAll.data?.me;
  const isLoggedOut = !user;

  const toggleMenu = () => {
    setOpened((opened) => !opened);
  };

  const globalSearchAccessToken =
    jwtCreateGlobalSearch.data?.coveoJwtCreate.jwt;

  const closeMobileMenu = () => setOpened(false);

  useEffect(() => {
    if (width && width >= breakpoints.lg) {
      setOpened(false);
    }
  }, [width, setOpened]);

  useEffect(() => {
    const mainHeaderBannerEl = document.getElementById("main-header-banner");

    // in order to avoid the banner from hiding the menu, hide it
    if (opened) {
      mainHeaderBannerEl?.classList.add("hidden");
    } else {
      mainHeaderBannerEl?.classList.remove("hidden");
    }

    return () => {
      mainHeaderBannerEl?.classList.remove("hidden");
    };
  }, [opened]);

  const plainLinks = [
    {
      title: t("footer.about_us.text"),
      href: t("footer.about_us.href"),
    },
    {
      title: t("footer.press.text"),
      href: t("footer.press.href"),
    },
    {
      title: t("footer.contact.text"),
      href: t("footer.contact.href"),
    },
    {
      title: t("footer.careers.text"),
      href: t("footer.careers.href"),
    },
  ];

  return (
    <>
      <button
        className="appearance-none bg-transparent border-none ml-lg px-md py-lg group"
        onClick={() => toggleMenu()}
      >
        <div
          className={cn(`tham tham-e-squeeze tham-w-6 hover:opacity-100`, {
            "tham-active": opened,
          })}
        >
          <div className="tham-box">
            <div className="tham-inner bg-gray-400 group-hover:bg-white" />
          </div>
        </div>
      </button>
      {opened && (
        <RemoveScroll>
          <div
            className={cn(
              "fixed left-0 bottom-0 right-0 top-[var(--header-nav-height)] w-full z-50 h-[calc(100dvh-var(--header-nav-height))] p-xl bg-white overflow-y-scroll",
            )}
          >
            <div className="flex flex-col justify-between h-full">
              <div className="pt-xl">
                {COVEO_ORGANIZATION_ID && globalSearchAccessToken && (
                  <GlobalSearchStandalone
                    accessToken={globalSearchAccessToken}
                    organizationId={COVEO_ORGANIZATION_ID}
                    variant="inline"
                    jwtRenewGlobalSearch={jwtRenewGlobalSearch}
                    placeholder={t("search.placeholder")}
                    inputClassName="border-primary border"
                    beforeSearchSubmit={closeMobileMenu}
                  />
                )}
                <Accordion
                  type="single"
                  collapsible
                  className="w-full mt-xl border-0 border-b border-solid border-secondary pb-xl"
                >
                  <PlatformSubMenu
                    key="platform"
                    wrapperComponent={MobileSubMenu}
                    compact={true}
                  />
                  <ApplicationSubMenu
                    key="applications"
                    wrapperComponent={MobileSubMenu}
                    compact={true}
                  />
                  <ShopSubMenu
                    key="shop"
                    wrapperComponent={MobileSubMenu}
                    compact={true}
                  />
                  <ResourcesSubMenu
                    key="resources"
                    wrapperComponent={MobileSubMenu}
                  />
                  <CommunitySubMenu
                    key="community"
                    wrapperComponent={MobileSubMenu}
                    compact={true}
                  />
                </Accordion>
                <div className="py-xl">
                  {plainLinks.map((link) => (
                    <a
                      key={link.href}
                      href={link.href}
                      className="block no-underline p-xl text-sm-semibold text-gray-light-600 hover:text-gray-light-700 hover:no-underline"
                    >
                      {link.title}
                    </a>
                  ))}
                </div>
                <div className="py-4xl px-xl space-y-xl border-y border-y-secondary">
                  <RegionLocaleSelector />
                </div>
              </div>
              <div className="pt-4xl pb-xl space-y-xl">
                <Button size="xl" className="w-full" asChild>
                  <a
                    href={t("footer.demo.href")}
                    className="no-underline hover:no-underline"
                  >
                    {t("footer.demo.text")}
                  </a>
                </Button>
                {isLoggedOut ? (
                  <Button
                    variant="outline"
                    size="xl"
                    className="w-full"
                    asChild
                  >
                    <a
                      href={t("footer.login.href")}
                      className="no-underline hover:no-underline"
                    >
                      {t("footer.login.text")}
                    </a>
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    size="xl"
                    className="w-full"
                    asChild
                  >
                    <a
                      href={t("footer.logout.href")}
                      className="no-underline hover:no-underline"
                    >
                      {t("footer.logout.text")}
                    </a>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </RemoveScroll>
      )}
    </>
  );
};
