import { gql } from "graphql-request";
import {
  ErrorMetadata,
  GeographicalRegionCode,
  ID,
  Locale,
} from "../shared/Base";

export const USER_CART_QUERY = gql`
  query UserCartQuery {
    userCart {
      item {
        id
        active
        userId
        itemsCount
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UserCartQueryDTO = {
  userCart: {
    item: {
      id: string | null;
      active: boolean | null;
      userId: number | null;
      itemsCount: number;
    } & ErrorMetadata;
  };
};

export type UserCartQueryParams = Record<string, never>;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    me {
      adminOn
      canAdmin
      avatarUrl
      displayName
      email
      id
      currencyCode
      geographicalRegion {
        code
      }
    }
  }
`;

export type CurrentUserQueryDTO = {
  me: null | {
    adminOn: boolean;
    canAdmin: boolean;
    avatarUrl: string;
    displayName: string;
    email: string;
    id: ID;
    currencyCode: string;
    geographicalRegion: {
      code: GeographicalRegionCode;
    };
  };
};

export type CurrentUserQueryParams = Record<string, never>;

export const CURRENT_USER_ALL_QUERY = gql`
  query CurrentUserAllQuery {
    userCart {
      item {
        id
        active
        userId
        itemsCount
      }
      errors {
        title
        message
      }
    }
    me {
      adminOn
      canAdmin
      avatarUrl
      displayName
      email
      id
      currencyCode
      geographicalRegion {
        code
      }
    }
  }
`;

export type CurrentUserAllQueryDTO = UserCartQueryDTO & CurrentUserQueryDTO;

export type CurrentUserAllQueryParams = Record<string, unknown>;

type HeaderBannerEntry = {
  readonly linkText: string;
  readonly linkUrl: string;
  readonly mobileLinkText?: string;
  readonly prefix: string;
  readonly slug: string;
  readonly title: string;
};

export type UserHeaderBannerQueryDTO = {
  userHeaderBanner: null | {
    dismissed: boolean;
    entry: HeaderBannerEntry;
  };
};

export type UserHeaderBannerQueryParams = {
  locale: string;
};

export const USER_HEADER_BANNER_QUERY = gql`
  query UserHeaderBanner($locale: Locale!) {
    userHeaderBanner(locale: $locale) {
      dismissed
      entry {
        linkText
        linkUrl
        mobileLinkText
        prefix
        slug
        title
      }
    }
  }
`;

export type UserCookieConsentQueryDTO = {
  userCookieConsent: {
    cookieConsentAt: string;
  };
};

export type UserCookieConsentQueryParams = Record<string, unknown>;

export const USER_COOKIE_CONSENT_QUERY = gql`
  query {
    userCookieConsent {
      cookieConsentAt
    }
  }
`;

export type UserPreferredLocalizationQueryDTO = {
  userPreferredLocalization: {
    geographicalRegionCode: GeographicalRegionCode;
    locale: Locale;
  };
};

export type UserPreferredLocalizationQueryParams = Record<string, unknown>;

export const USER_PREFERRED_LOCALIZATION_QUERY = gql`
  query {
    userPreferredLocalization {
      geographicalRegionCode
      locale
    }
  }
`;
