"use client";

import React, { useState } from "react";
import { LocaleSelector } from "./LocaleSelector";
import { RegionSelector } from "./RegionSelector";
import { Locale } from "../../../../services/graphql/shared/Base";
import { usePublicConfigContext } from "../../../../providers/PublicConfigProvider";

export const RegionLocaleSelector = ({
  triggerClassName,
  labelClassName,
}: {
  readonly triggerClassName?: string;
  readonly labelClassName?: string;
}) => {
  const [lastSelectedLocale, setLastSelectedLocale] = useState<
    Locale | undefined
  >(undefined);

  const { LOCALIZED_IN } = usePublicConfigContext();

  return (
    <>
      <RegionSelector
        triggerClassName={triggerClassName}
        labelClassName={labelClassName}
        lastSelectedLocale={lastSelectedLocale}
      />
      {LOCALIZED_IN && LOCALIZED_IN.length > 1 && (
        <LocaleSelector
          triggerClassName={triggerClassName}
          labelClassName={labelClassName}
          lastSelectedLocale={lastSelectedLocale}
          setLastSelectedLocale={setLastSelectedLocale}
        />
      )}
    </>
  );
};
