import React from "react";
import { FooterLink, FooterLinkProps } from "./FooterLink";

export interface FooterLinkListProps {
  title: string;
  links: Array<FooterLinkProps>;
}

export const FooterLinkList = ({ title, links }: FooterLinkListProps) => {
  return (
    <div className="mb-8">
      <div className="mb-4 font-medium text-gray-500">{title}</div>
      <ul className="list-none m-0 p-0">
        {links.map((link) => (
          <FooterLink key={link.label} {...link} />
        ))}
      </ul>
    </div>
  );
};
