import React from "react";
import { Footer } from "../components/Footer";
import { SubFooter } from "../components/SubFooter";
import { ReactQueryProvider } from "../providers/ReactQueryProvider";
import { PublicConfigProvider } from "../providers/PublicConfigProvider";
import { GraphQLProvider } from "../providers/GraphQLProvider";
import { UserProvider } from "../providers/UserProvider";

type FooterWrapperProps = {
  readonly contactPhoneNumber: string;
  readonly config: {
    readonly RAILS_ROOT_URL: string;
    readonly COVEO_ORGANIZATION_ID: string;
    readonly GRAPHQL_PUBLIC_API_URL: string;
    readonly GRAPHQL_PRIVATE_API_URL: string;
    readonly LOCALIZED_IN?: string[];
  };
  readonly locale: string;
};

export default function FooterWrapper({
  contactPhoneNumber,
  config,
  locale,
}: FooterWrapperProps) {
  return (
    <ReactQueryProvider>
      <PublicConfigProvider config={config}>
        <GraphQLProvider>
          <UserProvider locale={locale}>
            <div>
              <Footer contactPhoneNumber={contactPhoneNumber} />
              <SubFooter />
            </div>
          </UserProvider>
        </GraphQLProvider>
      </PublicConfigProvider>
    </ReactQueryProvider>
  );
}
