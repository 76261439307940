import { cn } from "@/lib/utils";
import React from "react";

export interface LinkItemIconProps {
  src: string;
  alt: string;
  className?: string;
}

export const LinkItemIcon = ({ src, alt, className }: LinkItemIconProps) => (
  <img
    src={src}
    alt={alt}
    className={cn("w-[24px] h-[24px] mr-xl", className)}
    width={24}
    height={24}
    loading="lazy"
  />
);
